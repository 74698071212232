import React from 'react';
import { Link } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import { motion } from 'framer-motion';
import "../style/navbar.scss";

function Navbar() {
    const getBrowser = () => {
        const userAgent = navigator.userAgent;
        if (/chrome/i.test(userAgent)) {
          return 'Chrome';
        } else if (/firefox/i.test(userAgent)) {
          return 'Firefox';
        } else if (/safari/i.test(userAgent)) {
          return 'Safari';
        } else {
          return 'Other';
        }
      };

      const handleWindowOpen = () => {
        const userAgent = navigator.userAgent;
        if (/chrome/i.test(userAgent)) {
          window.open('https://chromewebstore.google.com/detail/gurk/hohbfpdopadjniaeblmhkghpgeffigcc?hl=fr', '_blank');
        } else if (/firefox/i.test(userAgent)) {
          window.open('https://addons.mozilla.org/fr/firefox/addon/gurk/?utm_source=gurk.app&utm_medium=referral&utm_content=click', '_blank');
        } else if (/safari/i.test(userAgent)) {
          // window.open('https://apps.apple.com/us/app/gurk/id1589914890', '_blank');
        } else {
          window.open('https://chromewebstore.google.com/detail/gurk/hohbfpdopadjniaeblmhkghpgeffigcc?hl=fr', '_blank');
        }
      };

  return (
    <div className='navbar-big-container'>

    <div className='navbar-container'>
        <div className='elements-container'>

        <div className='navbar-elements'>
            <Link to="/">Home</Link>
        </div>
        <div className='navbar-elements'>
            <Link to="/dashboard">Dashboard</Link>
        </div>
        <div className='navbar-elements'>
            <Link to="/pricing">Pricing</Link>
        </div>
        <motion.div whileHover={{scale : 1.04}} className='download-gurk-button' onClick={()=>handleWindowOpen()}>+ Add <span className='navbar-browser'>to {getBrowser()}</span></motion.div>
        </div>
        <div className='navbar-elements'>
            <Link to="/logup">
                <CgProfile size={"5vh"} color='var(--background-color)'/>
            </Link>
        </div>
    </div>
    </div>
  )
}

export default Navbar;