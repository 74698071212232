import React, { useEffect, useState } from 'react';
import {motion} from "framer-motion"

const Loader = (props) => {
    
    const [status, setStatus] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setStatus(prevStatus => prevStatus < 8 ? prevStatus + 1 : 0);
        }, 100);

        // Clean up the interval on unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loader-big-container" style={{width : "100%", height : "100%", display : "flex", justifyContent : "center", alignItems : "center"}}>
            <div className="loader-small-container">
                <div className='row-1' style={{display : "flex"}}>
                    <motion.div className='element-11' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===0 || status===1 || status===2 || status===3||status===4)? 1 : 0}}></motion.div>
                    <motion.div className='element-12' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===1 || status===2 || status===3 || status===4||status===5)? 1 : 0}}></motion.div>
                    <motion.div className='element-13' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===2 || status===3 || status===4 || status===5||status===6)? 1 : 0}}></motion.div>
                </div>                
                <div className='row-2' style={{display : "flex"}}>
                    <motion.div className='element-21' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===1 || status===2 || status===3 || status===4||status===5)? 1 : 0}}></motion.div>
                    <motion.div className='element-22' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===2 || status===3 || status===4 || status===5||status===6)? 1 : 0}}></motion.div>
                    <motion.div className='element-23' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===3 || status===4 || status===5 || status===6||status===7)? 1 : 0}}></motion.div>
                </div>                
                <div className='row-3' style={{display : "flex"}}>
                    <motion.div className='element-31' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===2 || status===3 || status===4 || status===5||status===6)? 1 : 0}}></motion.div>
                    <motion.div className='element-32' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===3 || status===4 || status===5 || status===6||status===7)? 1 : 0}}></motion.div>
                    <motion.div className='element-33' style={{width : props.width+"px", height : props.width+"px", backgroundColor : props.color, borderRadius : props.width/7+"px", margin : props.width/50+"px"}} animate={{opacity : (status===4 || status===5 || status===6 || status===7||status===8)? 1 : 0}}></motion.div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
