import axios from 'axios';
import React, { useState } from 'react';
import {motion} from 'framer-motion';
import '../style/contact.scss';
import Loader from '../utils/Loader';
import Success from '../components/Success';
import Error from '../components/Error';

function Contact(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [page, setPage] = useState(0);

  const [errorEmail, setErrorEmail] = useState("");

  const sendMail = async () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  
    // Vérifie si l'adresse e-mail est valide en utilisant l'expression régulière
    const isValid = emailPattern.test(email);
    const containsYopmail = /@yopmail\.com/i.test(email);
    if(!isValid || containsYopmail) {
      setErrorEmail("Please enter a valid email address");
      return
    }
    try{
      setPage(998);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/contact`, { 
          name : name, 
          email : email,
          subject : props.sub,
          message : message 
      })
      if(response.data.success) {
        setPage(997);
        setErrorEmail("");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setPage(999)
      }

    } catch (error) {
      setPage(999)
    }
  };
  const returnAcceuil = ()=>{
    setPage(0)
  }

  return (

    <div className='contact-container'>
      {page===0 && (
        <>
        <input className='input-contact-standard' value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
        <input className='input-contact-standard' value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
        <motion.div initial={{height : "0px"}} animate={{height : errorEmail !== "" ? "" : "0px"}} className='error-email-contact'>{errorEmail}</motion.div>
        <textarea className='input-contact-big' value={message} onChange={e => setMessage(e.target.value)} placeholder="Message" />
        <motion.div whileHover={{scale : 1.05}} className='button-envoyer-contact' onClick={()=>sendMail()}>{props.sub==="contact"? "Contact Us" : "Partner with us"}</motion.div>
        </>
      )}

      {page===997 && (
        <div className='container-element-contact'>
        <Success returnAcceuil={returnAcceuil} message="the email was send successfully"/>
        </div>
      )}

      {page===998 && (
        <div className='container-element-contact'>
        <Loader width={50} color={"var(--background-color)"} />
        </div>
      )}

      {page===999 && (
        <div className='container-element-contact'>
        <Error returnAcceuil={returnAcceuil}/>
        </div>
      )}
    </div>
  );
}

export default Contact;