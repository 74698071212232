import React from 'react'
import "../style/footer.scss"
import { Link } from 'react-router-dom'

//dans les props, il y doit y avoir la couleur
function Footer(props) {

    const openInstagram = () => {
        window.open('https://www.instagram.com/gurk.extension')
    }
    const openTwitter = () => {
        window.open('https://twitter.com/gurkapp')
    }
    const openChromeStore = () => {
        window.open('https://chrome.google.com/webstore/detail/gurk/')
    }

  return (
    <div className='big-footer-container' style={{
        backgroundColor : props.backgroundColor? props.backgroundColor : "var(--background-color)",
        color : props.color? props.color : "white"
    }}>

    <div className='footer-container'>

        <div className='footer-column'>
            <Link to="/" className='footer-title'>GURK</Link>
            <Link to="/pricing">Basic</Link>
            <Link to="/pricing">Premium</Link>
            <div className='footer-element' onClick={()=>openChromeStore()}>Download</div>
        </div>

        <div className='footer-column'>

            <div className='footer-title'>CONNECT</div>
            <Link to="/contact">Contact Us</Link>
            <Link to="/contact-partner">Partner with us</Link>
            <Link to="/faq">FAQ</Link>
        </div>           
        
        <div className='footer-column'>
            <div className='footer-title'>SUPPORT</div>
            <Link to="/contact">Help Center</Link>
            <Link to="/dashboard">Account</Link>
            <Link to="/pricing">Gift</Link>
        </div>           
        
        <div className='footer-column'>
            <div className='footer-title'>FOLLOW US</div>
            <div className='footer-element' onClick={()=>openInstagram()}>Instagram</div>
            <div className='footer-element' onClick={()=>openTwitter()}>Twitter</div>
        </div>    
    </div>

    <div className='logo-conditions-container'>
        <Link to="/"><div className='logo-container'>
            <img className='logo-element' src={process.env.PUBLIC_URL + '/svg/logo.svg'} alt="Logo" />
        </div></Link>
        <div className='trucs-chiant-pas-interessant'>
            <Link to="/privacy" className='privacy-container'>Privacy</Link>
            <Link to="/terms" className='privacy-container'>Terms</Link>
            <Link to="/legal" className='privacy-container'>Legal</Link>

        </div>
    </div>


    <div className='svg-footer-container'>
    <svg viewBox='0 0 4400 1334' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='none'>
        <defs>
            <clipPath id="clip0"><rect x="0" y="1146" width="4400" height="1334"/></clipPath>
        </defs>
        <g clip-path="url(#clip0)" transform="matrix(1 0 0 1 0 -1146)">
            <path d="M0 1343.12 880 1150.58 1760 1146 2640 1320.2 3520 1278.94 4400 1214.76 4400 2480 3520 2480 2640 2480 1760 2480 880 2480 0 2480Z" fill="#0D0D0D" fill-rule="evenodd" fill-opacity="0.2"/>
            <path d="M0 1393.22 880 1594.99 1760 1361.13 2640 1310.68 3520 1274 4400 1365.71 4400 2480 3520 2480 2640 2480 1760 2480 880 2480 0 2480Z" fill="#0D0D0D" fill-rule="evenodd" fill-opacity="0.2"/>
            <path d="M0 1526 880 1645.25 1760 1686.53 2640 1782.85 3520 1567.28 4400 1659.01 4400 2480 3520 2480 2640 2480 1760 2480 880 2480 0 2480Z" fill="#0D0D0D" fill-rule="evenodd" fill-opacity="0.2"/>
            <path d="M0 2035.31 880 1774 1760 2062.82 2640 1971.13 3520 2104.08 4400 2076.57 4400 2480 3520 2480 2640 2480 1760 2480 880 2480 0 2480Z" fill="#0D0D0D" fill-rule="evenodd" fill-opacity="0.2"/>
            <path d="M0 2214.05 880 2195.71 1760 2177.37 2640 2232.39 3520 2269.07 4400 2104 4400 2480 3520 2480 2640 2480 1760 2480 880 2480 0 2480Z" fill="#0D0D0D" fill-rule="evenodd" fill-opacity="0.2"/>
        </g>
    </svg>
    </div>
    </div>
  )
}

export default Footer