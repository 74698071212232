import React, { useEffect, useState } from 'react'
import Payment from '../components/Payment'
import Payment2 from '../components/Payment2'
import Offres from '../components/Offres'
import "../style/pricing/pricing.scss"
import axios from 'axios'
import Loader from '../utils/Loader'
import { loadStripe } from '@stripe/stripe-js'
import { useLocation, useNavigate } from 'react-router-dom'
import Success from '../components/Success'
import Error from '../components/Error'
import Cancel from '../components/Cancel'
import Subscription from '../components/Subscription'
import { motion } from 'framer-motion'
import { IoMailUnreadOutline } from 'react-icons/io5'
import { PiArrowLeftLight } from 'react-icons/pi'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);


function Pricing() {
    const [page, setPage] = useState(0)
    const [plan, setPlan] = useState("free")
    const [planToBuy, setPlanToBuy] = useState("free")

    const [currentSubscription, setCurrentSubscription] = useState(null)
    const [clientSecret, setClientSecret] = useState(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    const [isCookie, setIsCookie] = useState(false)

    useEffect(()=>{
      axios.get(`${process.env.REACT_APP_API_URL}/api/website/verify`, {withCredentials : true}).then((res) => {setIsCookie(res.data.success)})
    },[])
    const location = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [error, setError] = useState('')
    const [login , setLogin] = useState(true)
    const [user, setUser] = useState(null)
    const [validationCode, setValidationCode] = useState('')
    const [emailConfirmed, setEmailConfirmed] = useState(false)
    const [emailValidation, setEmailValidation] = useState(false)

  //   useEffect(() => {
  //     const params = new URLSearchParams(location.search);
  //     if (params.get('redirect_status') === "succeeded") {
  //         setPage(997);
  //     }
  // }, [location]);


  async function setDefaultPaymentMethod() {
    try{
      setPage(998)
      const setupIntentId = new URLSearchParams(location.search).get('setup_intent');
      const planId = new URLSearchParams(location.search).get('plan');
      const subscriptionDetails = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/confirm-setup-intent`, {setupIntentId : setupIntentId, planId : planId})
      setPage(997)
      //on a des paramètres donc on redirige
      navigate("/pricing")
      // const customerId = subscriptionDetails.data.subscriptionDetails.customer_id
      // const stripe = await stripePromise;
      // const paymentMethod = await stripe.paymentMethods.attach(
      //   paymentMethodId,
      //   {customer: customerId}
      //   );
        
      // Set it as the default Payment Method
      // const customer = await stripe.customers.update(
      //   customerId,
      //   {invoice_settings: {default_payment_method: paymentMethod.id}}
      // );
    } catch (err) {
      console.log(err)
      setPage(999)
    }
    // Attach the Payment Method to the Customer
    
  }

    //fetch la subscription de l'utilisateur

    useEffect(()=>{
      const params = new URLSearchParams(location.search);
      if (params.get('redirect_status') === "succeeded") {
          setDefaultPaymentMethod()
          return
      }
      if(params.get("session_id")){
        setPage(997)
        navigate("/pricing")
        return
      }
    }, [])
    useEffect(()=> {
      const fetchData = async () => {
        setPage(998)
        try{
          if(isCookie === false) {setCurrentSubscription({plan_id : "free"}); setPage(0); return}
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/getSubscription`, {withCredentials : true})
          console.log(response.data)
          if(response.data.success) {
            setCurrentSubscription(response.data.subscriptionDetails)
          } else {
            setCurrentSubscription({plan_id : "free"})
          }
          setPage(0)
        } catch (err) {
          console.log(err)
          setPage(999)
        }
      }
      fetchData()
    }, [isCookie])

    const handlePaymentIntent = async (plan) => {
      try {
        setPage(998)
        if(isCookie === false) {
          setPage(101); 
          return
        }
        const user = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/user`,{ withCredentials: true })
        if(!user.data.emailValid) {
          setEmailValidation(user.data.email)
          sendEmailWithCode(user.data.email);          
          return
        }
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/create-setup-intent`,
            {planId : plan}
        )
        setClientSecret({clientSecret : response.data.clientSecret})
        setPlan(plan)
        setPage(1)
      } catch (err) {
        setPage(999)
      }
    // Fetch the client secret from your server
  };
  

  const changePaymentDetails = async () => {
    try{
      setPage(998)
      if(isCookie === false) {setPage(0); return}
        const stripe = await stripePromise;
        // Get Checkout Session ID from your server
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/create-checkout-session`, {});

        const { sessionId } = response.data;

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result.error.message);
            setPage(999)
        } else {
            setPage(997)
        }

    } catch (err) {
        console.log(err)
        setPage(999)
    }
    };

    const handleChangeSubscription = async () => {
      try{
        setPage(998)
        if(isCookie === false) {setPage(101); return}
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/subscription-update`, {newPlanId : plan})
        if(res.data.success){
          setPage(997)
        } else {
          setPage(999)
        }
      } catch (err) {
        setPage(999)
      }
    }


    const subscribePlan = async (plan) => {
        setPlan(plan)
        await handlePaymentIntent(plan)
        // setPage(1)
    }

    const changePlan = (plan) => {
        setPage(3)
        setPlan(plan)
    }

    const cancelPlan = () => {
        setPage(4)
    }

    const showDetails = async () => {
        try {
          setPage(998)
          if(isCookie === false) {setPage(0); return}
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/getSubscriptionDetails`, {withCredentials : true})
          setSubscriptionDetails(response.data.subscription)
          setPage(5)
        } catch (err) {
          setPage(999)
        }
    }

    const returnAcceuil = () => {
        setPage(0)
        setPlan("")
        if(currentSubscription === null) {
          window.location.reload()
        }
    }

    const returnAcceuilReload = () => {
      setPage(0)
      setPlan("")
      window.location.reload()
  }

    const paymentIntentFailed = async () => {
      setPage(998)
      try{
        if(isCookie === false) {setPage(0); return}
        await axios.post(`${process.env.REACT_APP_API_URL}/api/website/setup-intent-failed`, {
          payment_intent : clientSecret.clientSecret,
          });
          setClientSecret(null);
          setPage(0)
        } catch (err) {
          setPage(999)
        }
      }

  //-------------------LOGIN SIGNUP-------------------



  

  const handleLogup = async () => {
    if(login){
      if(email !== "" && password !== ""){
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(!emailRegex.test(email)) {
          setError("Invalid email format");
          return;
        }
        setPage(998)
        setError("")
        try {
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/login`, {
            email : email,
            password : password,
          })
          if(res.data.success === false){
            console.log(res.data)
            setError(res.data.message)
          }else{
            setUser(res.data.message)
            if(res.data.emailValid) {
              handlePaymentIntent(plan)
            } else {
              setEmailValidation(email)
              sendEmailWithCode(email)
            }
          }
        } catch (error) {
            console.log(error)
          setError(error.message)
        }
      }else{
        setError("Veuillez remplir tous les champs")
      }
    } else {
      if(name!== "" && email !== "" && password !== "" && passwordConfirm !== ""){
        if(password === passwordConfirm){
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if(!emailRegex.test(email)) {
            setError("Invalid email format");
            return;
          }
          setPage(998)
          setError("")
          try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/signup`, {
              name : name,
              email : email,
              password : password,
            })
            if(res.data.error){
              setError(res.data.error)
            }else{
              setUser(res.data)
            }
            if(res.data.emailValid) {
              handlePaymentIntent(plan)
            } else {
              setEmailValidation(email)
              sendEmailWithCode(email)
            }
          } catch (error) {
            setError(error.message)
          }
        }else{
          setError("Les mots de passe ne correspondent pas")
        }
      }else{
        setError("Veuillez remplir tous les champs")
      }
    }
  }

  
  
  const handleChangeValidationCode = (code) => {
    if (!isNaN(code) && code.length <= 6) {
      setValidationCode(code);
    }
  }

  const handleEnterCode = (code, status) => {
    if(code.length !== 6) return;
    if(status === "valider-email"){
      validerCodeEmail(code)
    }
  }

    
  const sendEmailWithCode = async (email) => {  
    // Vérifie si l'adresse e-mail est valide en utilisant l'expression régulière
    window.scrollTo(0, 0); // scroll to the top of the page
    setPage(998)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/email/send-code`, {email : email})
      if(response.data.success){
        setPage(102)
      }
    } catch (err){
      console.log(err)
      setPage(999)
    }
  }

  const changePage = (val) => {
    setPage(val)
  }
  
  const validerCodeEmail = async (code) => {
    try {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/email/changeEmail`, { email : emailValidation, code : code})
      console.log(response.data)
      if(response.data.success){
        setValidationCode("")
        setError("")
        console.log("ici")
        handlePaymentIntent(plan)
      } else {
        setError("wrong code")
        setPage(102)
      }
    } catch (err){
      console.log(err)
      setPage(999)
    }
  }

  return (
    <div className='pricing-container'>
      {((currentSubscription !== null) && (page===0)) && 
        <Offres cancelPlan={cancelPlan} changePlan={changePlan} subscribePlan={subscribePlan} showDetails={showDetails} currentSubscription={currentSubscription}/>
      }
      {(page===1 && clientSecret !== null) && <Payment  planId={plan} changePage={changePage} clientSecret={clientSecret} returnAcceuil={returnAcceuil} paymentIntentFailed={paymentIntentFailed}/>}
      {page===2 && <Payment planId={plan} returnAcceuil={returnAcceuil} changePage={changePage}/>}
      {page===3 && <Payment2 returnAcceuil={returnAcceuil} planId={plan} currentPlan={currentSubscription.plan_id} handleChangeSubscription={handleChangeSubscription}/>}
      {page===4 && <Cancel returnAcceuil={returnAcceuil} subscription={currentSubscription} changePage={setPage}/>}
      {page===5 && <Subscription returnAcceuil={returnAcceuil} subscription={subscriptionDetails} cancel={cancelPlan} changePaymentDetails={changePaymentDetails}/>}
      
      {page===101 && (
        <div className='login-big-container'>

        <div className='login-container-pricing'>
        <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='arrow-back-container-login-page'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>

        <motion.div className='login-element-container' initial={{height : login? "0px" : ""}} animate={{height : login? "0px" : ""}}>
            <div className='login-title'>Name</div>
            <input className='login-input' type="text" value={name} onChange={e => setName(e.target.value)}/>
        </motion.div>

        <div className='login-element-container'>
            <div className='login-title'>Email</div>
            <input className='login-input' type="text" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>

        <div className='login-element-container'>
            <div className='login-title'>Password</div>
            <input className='login-input' type="password" value={password} onChange={e => setPassword(e.target.value)}/>
        </div>

        <motion.div className='login-element-container' initial={{height : login? "0px" : ""}} animate={{height : login? "0px" : ""}}>
            <div className='login-title'>Confirm Password</div>
            <input className='login-input' type="password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
        </motion.div>
        
        <motion.div className='login-button' whileHover={{scale : 1.05}} onClick={()=>{handleLogup()}}>{login? "login" : "signup"}</motion.div>
        <motion.div className='login-error' animate={{height : error===""? "0px" : "", marginTop : error===""? "0px" : "10px", opacity : error===""? 0 : 1}}>{error}</motion.div>
        <div className='login-switch' onClick={()=>{setLogin(!login)}}>{login? "Vous n'avez pas de compte " : "Vous avez déjà un compte ? "}<strong>{login? "S'inscrire" : "Se connecter"}</strong></div>
        </div>
        </div>
      )}

      {page===102 && (
        <div className='login-big-container'>
        <div className='error-confirm-container flex-column-sa'>
          <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <div className='flex'>A validation mail has been send to {emailValidation}</div>
            <div className='flex'><IoMailUnreadOutline size={100} color="var(--background-color)"/>
            <div><input className='validation-code-email-input' placeholder="Validation code" value={validationCode} onChange={(e)=>handleChangeValidationCode(e.target.value)}/></div>
            {error!=="" && <div className='error-validation-code'>{error}</div>}
          </div>
          <motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleEnterCode(validationCode, "valider-email")}>Confirm the code</motion.div>
        </div>
        </div>
      )}
      
      
      
      {page===997 && <Success returnAcceuil={returnAcceuilReload}/>}
      {page===998 && <Loader width={50} color={"var(--background-color)"}/>}
      {page===999 && <Error returnAcceuil={returnAcceuil}/>}
    </div>
  )
}

export default Pricing;