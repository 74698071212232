import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline } from "react-icons/io5";
import {motion} from "framer-motion"
import "../style/pricing/payment.scss"

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);


const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [enCours, setEnCours] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(enCours) return
    setEnCours(true)
    axios.post(`${process.env.REACT_APP_API_URL}/api/website/create-subscription`, {
      planId : props.planId
    })

    if (!stripe || !elements) {
      return;
    }
    const {error, paymentIntent} = await stripe.confirmSetup({
      elements,
      confirmParams : {
        return_url :  `${process.env.REACT_APP_PUBLIC_URL}/pricing?plan=${props.planId}`
      }
    });
    if(error) {
      console.log(error)
    }
    props.changePage(998);
  };

  
  return (
      <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='button-submit-form-stripe' type="submit" disabled={!stripe}>
        {!enCours? <>Try {props.getOffre()}</> : <>...</>}
      </button>
    </form>
  );
};


const premium_monthly = process.env.REACT_APP_PREMIUM_MONTHLY;
const premium_annual = process.env.REACT_APP_PREMIUM_ANNUAL;
const basic_monthly = process.env.REACT_APP_BASIC_MONTHLY;
const basic_annual = process.env.REACT_APP_BASIC_ANNUAL;

const Payment = (props) => {

    const [clientSecret, setClientSecret] = useState(null);

    const handlePaymentIntentFailed = async () => {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/website/payment-intent-failed`, {
        //   payment_intent: paymentIntent.id,
        payment_intent : clientSecret.clientSecret,
        });
        setClientSecret(null);
      }

    const handlePaymentIntent = async () => {
        console.log("ici")
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/create-payment-intent`,
            {planId : props.planId}
        )
        setClientSecret({clientSecret : response.data.clientSecret})
      // Fetch the client secret from your server
    };

    const getOffre = () => {
      if(props.planId === "free") return "Free"
      if(props.planId === basic_monthly || props.planId === basic_annual) return "Basic"
      if(props.planId === premium_monthly || props.planId === premium_annual) return "Premium"
    }

    const getFrequence = () => {
      if(props.planId === "free") return ""
      if(props.planId === basic_monthly || props.planId === premium_monthly) return "Monthly"
      if(props.planId === basic_annual || props.planId === premium_annual) return "Annual"
    }

    const getFacture = () => {
      if(props.planId === "free") return "Free"
      if(props.planId === basic_monthly) return "3.99€/Mo"
      if(props.planId === basic_annual) return "36€/Year"
      if(props.planId === premium_monthly) return "15.99€/Mo"
      if(props.planId === premium_annual) return "144€/Year"
    }

    return (
      <div className='payment-subscription-container'>

        <div className='payment-stripe-form-container'>
          <div className='header-payment-form'>
            <motion.div whileHover={{scale : 1.1}} className='arrow-back-container' onClick={()=>props.paymentIntentFailed()}><IoArrowBackOutline/></motion.div>
            <div>Entrez vos informations de paiement</div>
          </div>
        {props.clientSecret !== null && (
          <Elements stripe={stripePromise} options={props.clientSecret} >
                <CheckoutForm planId={props.planId} getOffre={getOffre} changePage={props.changePage} />
            </Elements>
        )}
        </div>

        <div className='recapitulatif'>
          <div className='recapitulatif-title'>{getOffre()} ({getFrequence()})</div>
          <div className='recapitulatif-price'>Or {getFacture()}</div>
          <div className='separator-payment'></div>
          <AdvantagesSubscription getOffre={getOffre}/>
          <div className='separator-payment'></div>
          <AvisPositifs/>
        </div>
      </div>
    );
}

export default React.memo(Payment);



const avisPositifs = [
  {
    name  :"Jean Castex",
    image : "Jean.jpeg",
    comment : "La planification de ma journée n'a jamais été aussi fluide grâce à la synchronisation transparente entre mon Calendrier Google et ma montre connectée. L'expérience premium a vraiment boosté ma productivité!"
  },
  {
    name : "Katia Ackerman",
    image  :"Katia.jpeg",
    comment : "Les widgets inclus dans le plan payant sont devenus mes compagnons indispensables pour rester informé, motivé et divertir pendant mes pauses. Un investissement qui a clairement amélioré mon quotidien!"
  },
  {
    name : "Keylan LV",
    image : "Keylan.jpeg",
    comment : "Entre la bourse en temps réel, les métriques PC et les citations motivantes, le plan payant a transformé mon bureau en un hub de productivité ultime. Un choix incontournable pour les travailleurs acharnés!"
  },
  {
    name : "Nathalie Mercier",
    image : "Nathalie.jpeg",
    comment : "L'accès aux images d'Unsplash et actualités directement sur ma page d'accueil m'inspire au quotidien. La productivité est à son comble avec ces sources d'inspiration visuelle et d'actualités pertinentes!"
  },
  {
    name : "Robert Belgrade",
    image : "Robert.jpeg",
    comment : "Les recommendations personnalisées et les faits amusants quotidiens sont devenus un moyen ludique d'optimiser ma routine. Chaque jour apporte une nouvelle dose de productivité et de connaissances!"
  },
  {
    name : "Steven Sionihc",
    image : "Steven.jpeg",
    comment : "Depuis que j'ai opté pour le plan premium, la productivité est devenue une seconde nature. Une extension incontournable pour ceux qui recherchent une expérience personnalisée et productive sur leur page d'accueil!"
  }
]



function AvisPositifs () {
  const [numeroAvis, setNumeroAvis] = useState(0);
  const [animation, setAnimation] = useState(false);


  const goLeft = async ()=>{
    setAnimation(true)
    await new Promise(resolve => {setTimeout(() => {resolve()}, 500);})
    if(numeroAvis===0) setNumeroAvis(avisPositifs.length-1)
    else setNumeroAvis(numeroAvis-1)
    setAnimation(false)
  }
  const goRight = async ()=>{
    setAnimation(true)
    await new Promise(resolve => {setTimeout(() => {resolve()}, 500);})
    if(numeroAvis===avisPositifs.length-1) setNumeroAvis(0);
    else setNumeroAvis(numeroAvis+1)
    setAnimation(false)
  }


  return (
    <div className='avis'>

      {/* Chevron Left */}
      <motion.div className='chevron-avis' whileHover={{scale : 1.1}} onClick={()=>goLeft()}>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M15 6l-6 6l6 6" />
        </svg>
      </motion.div>

      <motion.div className='avis-container'
        animate={{opacity : animation? 0 : 1}}
        transition={{duration : 0.5}}
      >
        <div className='avis-header-container'>
          <div className='image-container-payment'><img style={{width : "25px", height : "25px"}}src={`./img/persons/${avisPositifs[numeroAvis].image}`} alt=""/></div>
          <div className='avis-texte-name'>{avisPositifs[numeroAvis].name}</div>
        
        </div>
        <div className='avis-texte-container'>
          <div className='avis-texte-comment'>"{avisPositifs[numeroAvis].comment}"</div>
        </div>
      </motion.div>

      {/* Chevron right */}
      <motion.div className='chevron-avis' whileHover={{scale : 1.1}} onClick={()=>goRight()}>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M9 6l6 6l-6 6" />
        </svg>
      </motion.div>
    </div>
  )
}

function AdvantagesSubscription(props){

  const accessFree = ["Favorites", "To do List", "Notes", "Reminders"]
  const accessBasic = ["Unsplash pictures for background", "Google Calendar (max 5 calendars)", "Google Gemini", "Daily Fun Fact", "Motivational Quotes"]
  const accessPro = ["Stock Market", "Maps", "Google Calendar (unlimited calendars)"]
  
  
  return (
    <>
    <div className='autre-recap'>Included :</div>
    <div className='advantages-paiement'>
      {accessBasic.map((benefice, index)=>(
        <>
        <div key={index} className='benefice-container-payment'><VIP/><div>{benefice}</div></div>
        </>
      ))}
      {props.getOffre()==="Premium" && (
        <>
        {accessPro.map((benefice, index)=>(
          <>
          <div key={index} className='benefice-container-payment'><VIP/><div>{benefice}</div></div>
          </>
          ))}
        </>
      )}
    </div>
  </>
  )
}

export {AdvantagesSubscription, AvisPositifs};

function VIP(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-vip" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 5h18" />
      <path d="M3 19h18" />
      <path d="M4 9l2 6h1l2 -6" />
      <path d="M12 9v6" />
      <path d="M16 15v-6h2a2 2 0 1 1 0 4h-2" />
    </svg>
  )
}

