import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LogupPage from './pages/Logup';
import GooglePage from './pages/Google';
import HomePage from './pages/Home';
import Navbar from './utils/Navbar';
import "./style/general.scss"
import "./style/variables.scss"
import Pricing from './pages/Pricing';
import Footer from './utils/Footer';
import Dashboard from './pages/Dashboard';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Privacy from './pages/Privacy';
import axios from 'axios';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QueryHandler() {
  let query = useQuery();

  useEffect(() => {
    const token = query.get('token');
    const page = query.get('page');

    const loadToken = async (token, page) => {
      if (token) {
        if(token === 'null') {
          await axios.get(`${process.env.REACT_APP_API_URL}/api/website/logout`, { withCredentials: true })
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL}/api/website/setToken`, { token: token }, { withCredentials: true })
        }
      }

      if (page) {
        switch (page) {
          case 'change-payment':
            window.location.href = '/pricing';
            break;
          case 'dashboard':
            window.location.href = '/dashboard';
            break;
          default:
            break;
        }
      }
    }
    

    loadToken(token, page);
  }, []);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <QueryHandler/>
      <Routes>
        <Route path="/" exact element={<HomePage/>} />
        <Route path="/logup" element={<LogupPage/>} />
        <Route path="/google" element={<GooglePage/>}/>
        <Route path='/pricing' element={<Pricing/>}/>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/contact' element={<Contact sub="contact"/>}/>
        <Route path='/contact-partner' element={<Contact sub="partner"/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/privacy' element={<Privacy title="privacy"/>}/>
        <Route path='/legal' element={<Privacy title="legal"/>}/>
        <Route path='/terms' element={<Privacy title="terms"/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;