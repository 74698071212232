import React, { useEffect, useState } from 'react'
import "../style/home.scss"
import { AnimatePresence, motion } from "framer-motion"
import PremiumSvg from '../utils/PremiumSvg';
import BasicSvg from '../utils/BasicSvg';
import {  FaCheck, FaClock, FaCloud, FaNetworkWired, FaPen, FaStar } from "react-icons/fa6";
import { FaMapSigns, FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineStock } from "react-icons/ai";
import { IoMdFitness } from 'react-icons/io';
import { IoTimerOutline } from 'react-icons/io5';
import { GoSmiley } from "react-icons/go";
import { RiDoubleQuotesL } from "react-icons/ri";


function Home() {
  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    for (const card of document.getElementsByClassName("card-container")) {
      const rect = card.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  };
  
  const [flipped, setFlipped] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const [front, setFront] = useState([true, true, true, true, true, true, true, true, true, true, true, true, true]);
  
  const changeElement= async (value) => {
    console.log(value)
    const newflipped = [...flipped]
    newflipped[value] = !newflipped[value]
    setFlipped(newflipped)
    console.log(flipped)
    
    await new Promise(r => setTimeout(r, 250));

    const newfront = [...front]
    newfront[value] = !newfront[value]
    setFront(newfront)
  }
  
  const [headline, setHeadline] = useState(['c', 'u', 's', 't', 'o', 'm', 'i', 'z', 'a', 't', 'i', 'o', 'n']);
  const headlines = [
    ["w", "i", "d", "g", "e", "t", "s"],
    ["p", "a", "g", "e"],
    ["c", "h", "o", "i", "c", "e", "s"],
    ['c', 'u', 's', 't', 'o', 'm', 'i', 'z', 'a', 't', 'i', 'o', 'n'],
    ['p', 'r', 'o', 'd', 'u', 'c', 't', 'i', 'v', 'i', 't', 'y'],
    ['s', 'p', 'a', 'c', 'e']
  ]

  const [typing, setTyping] = useState(false);
  const [numeroHeadline, setNumeroHeadline] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      if(typing){
        if(headline.length === headlines[numeroHeadline].length){
          setTyping(false)
          return
        } else {
          const newHeadline = [...headline]
          newHeadline.push(headlines[numeroHeadline][newHeadline.length])
          setHeadline(newHeadline)
        }
      } else {
        if(headline.length === 0){
          setNumeroHeadline((numeroHeadline + 1) % headlines.length)
          setTyping(true)
          return
        } else {
          setHeadline(headline.slice(0, headline.length - 1));
        }
      }
    }, 100); // tap a letter every 2 seconds
    return () => clearTimeout(timer); // Clean up on component unmount
  }, [headline, typing, numeroHeadline]);
  
    const getBrowser = () => {
      const userAgent = navigator.userAgent;
      if (/chrome/i.test(userAgent)) {
        return 'Chrome';
      } else if (/firefox/i.test(userAgent)) {
        return 'Firefox';
      } else if (/safari/i.test(userAgent)) {
        return 'Safari';
      } else {
        return 'Chrome';
      }
    };
  
    const handleWindowOpen = () => {
      const userAgent = navigator.userAgent;
      if (/chrome/i.test(userAgent)) {
        window.open('https://chromewebstore.google.com/detail/gurk/hohbfpdopadjniaeblmhkghpgeffigcc?hl=fr', '_blank');
      } else if (/firefox/i.test(userAgent)) {
        window.open('https://addons.mozilla.org/fr/firefox/addon/gurk/?utm_source=gurk.app&utm_medium=referral&utm_content=click', '_blank');
      } else if (/safari/i.test(userAgent)) {
        // window.open('https://apps.apple.com/us/app/gurk/id1589914890', '_blank');
      } else {
        window.open('https://chromewebstore.google.com/detail/gurk/hohbfpdopadjniaeblmhkghpgeffigcc?hl=fr', '_blank');
      }
    };
  
  return (
    <div className='home-container'>
      <div className='home-header-container'>
        <div className='shock-phrase-home-container'>
          <div className='shock-phrase-title'>Your browser</div>
          <div className='shock-phrase-change-container'>
            <motion.div>
            <span className='shock-phrase-title'>Your </span>
              {headline.map((letter, index) => {
                return (
                  <motion.span
                  className='shock-phrase-variant'
                    key={index}
                  >
                    {letter}
                  </motion.span>
                );
              })}
            </motion.div>
            <div className='typing-cursor'></div>

          </div>
          <div className='shock-phrase-description'>Increase your productivity with a customized space</div>
          <motion.div whileHover={{scale : 1.05}} className='call-button' onClick={()=>handleWindowOpen()}>Add to {getBrowser()}, it is free !</motion.div>
        </div>
        <div className='image-home-page-container'>
          <img className='image-element-home' src='./images/HomePage.png' alt='homepage'/>
        </div>
      </div>

      <div className='home-page-container'>
        <div>
          <span className='bigphrase-container'>Transform your New Tab into a customizable dashboard, to see al your day at a glance. </span>
          <span className='bigphrase-container-small'>All the infos you need are available.</span>
        </div>
      </div>

      <div className='home-page-section-title'>A lot of widgets</div>
      
      <div className='widget-list-container'>


      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(0)}
            animate={{ rotateY: flipped[0] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[0] ? (
          <motion.div animate={{opacity : !flipped[0]? 1 : 0}}>
            <FaStar size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Favorites</div>
            <div className='widget-description'>Gurk syncronize for you your chrome favorites, and present them to you in a navigable way in order to retrieve the website you need. The perfect widget if you have to many favorites, and they don't stay in the chrome favorite bar !</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[0]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration favoris.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>          
          </motion.div>
        )}    
      </motion.div>   

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(1)}
            animate={{ rotateY: flipped[1] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[1] ? (
          <motion.div animate={{opacity : !flipped[1]? 1 : 0}}>
            <FaRegCalendarAlt size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Calendar</div>
            <div className='widget-description'>Choose the calendars you want to display, and we will display to you all the events to come in the differents calendars you have, in order to see in a fraction of second what is about to come and where</div>
            <Basic/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[1]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration calendar.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>      

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(2)}
            animate={{ rotateY: flipped[2] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[2] ? (
          <motion.div animate={{opacity : !flipped[2]? 1 : 0}}>
            <FaCheck size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>To Do List</div>
            <div className='widget-description'>Gurk provide you a To Do List widget, that allows you to manage all your taks and your goals of the day. Just add them, and we will display them as you want</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[2]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration todolist.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>   

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(3)}
            animate={{ rotateY: flipped[3] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[3] ? (
          <motion.div animate={{opacity : !flipped[3]? 1 : 0}}>
            <FaClock size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Reminders</div>
            <div className='widget-description'>The Reminders widget allows you to focus deeply into your work, but have the certitude to have a reminder when something important happend (a meeting, deadline...). You can customize the reminders to handle more or less anything !</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[3]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration reminders.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>   

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(4)}
            animate={{ rotateY: flipped[4] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[4] ? (
          <motion.div animate={{opacity : !flipped[4]? 1 : 0}}>
            <FaPen size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Notes</div>
            <div className='widget-description'>You need to remember something not too long for a short period of time ? Gurk provide the perfect solution : THe note widget allows you to write some short notes, that will be displayed immediatly when you arrives on the chrome NewTab</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[4]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration Notes.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>   

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(5)}
            animate={{ rotateY: flipped[5] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[5] ? (
          <motion.div animate={{opacity : !flipped[5]? 1 : 0}}>
            <AiOutlineStock strokeWidth={50} size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Stock market</div>
            <div className='widget-description'>You want to see the evolution of a particular stock or a particular market ? Gurk provides you a stock market widget, that will display the stock market's data in some very readable charts. You also have the possibility to see the details of a stock</div>
            <Premium/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[5]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration stockMarket.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div> 

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(6)}
            animate={{ rotateY: flipped[6] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[6] ? (
          <motion.div animate={{opacity : !flipped[6]? 1 : 0}}>
            <IoMdFitness size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Google Fit</div>
            <div className='widget-description'>Link your Google Fit account, and Gurk will display the differents informations of the google fit Account, like the number of steps, or the heart rate</div>
            <Basic/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[6]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration Google Fit.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>   
      
      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(7)}
            animate={{ rotateY: flipped[7] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[7] ? (
          <motion.div animate={{opacity : !flipped[7]? 1 : 0}}>
            <FaCloud size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Weather</div>
            <div className='widget-description'>If you want to see the weather of the place you are, gurk provide you a weather widget, in order to see in details the differents informations about the location (temperature, sun, wind...)</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[7]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration Weather.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>   

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(8)}
            animate={{ rotateY: flipped[8] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[8] ? (
          <motion.div animate={{opacity : !flipped[8]? 1 : 0}}>
            <IoTimerOutline size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Pomodoro</div>
            <div className='widget-description'>Did you heard about the Pomodoro work technique ? We have. And it is so efficient that we dedicated a customizable widget. You can be totaly isolated, or let the timer running in the background. In both case, gurk will tell you when it is the time to work or to take a break.</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[8]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration pomodoro.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>     

      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(9)}
            animate={{ rotateY: flipped[9] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[9] ? (
          <motion.div animate={{opacity : !flipped[9]? 1 : 0}}>
            <FaMapSigns size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Maps</div>
            <div className='widget-description'>Wants to have google Maps directly in the chrome New Page ? We have a special widget for you : a Map widget, that allows you to see the differents trajects available between a location A and B, and the travel time</div>
            <Premium/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[9]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration maps.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>              
          </motion.div>
        )}    
      </motion.div>   
      </div>      

      <div className='home-page-section-title'>And much more</div>


      <div className='widget-list-container'>
      
      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(10)}
            animate={{ rotateY: flipped[10] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[10] ? (
          <motion.div animate={{opacity : !flipped[10]? 1 : 0}}>
            <GoSmiley size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Greetings</div>
            <div className='widget-description'>Gurk is very polite. It says "good morning", "good evening" and "good night", according to your schedule. Just enter the differents time you want it to switch, and it will automaticaly adpat itself !</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[10]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration greetings.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>
     
      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            // onClick={() => changeElement(11)}
            // animate={{ rotateY: flipped[11] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            // transition={{duration : 0.5}}
          >
        {front[11] ? (
          <motion.div animate={{opacity : !flipped[11]? 1 : 0}}>
            <FaNetworkWired size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Ping</div>
            <div className='widget-description'>Gurk provide you the possibility to have one directly in the chrome page one of the most important PC metrics when you are on the web : the ping. Just say you want it, and we will display it</div>
            <Free/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[11]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration ping.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>
     
      <motion.div className='widget-description-container'
            whileHover={{ cursor : "pointer", scale : 1.02}}
            onClick={() => changeElement(12)}
            animate={{ rotateY: flipped[12] ? 180 : 0 }}
            style={{ transformStyle: "preserve-3d" }}
            transition={{duration : 0.5}}
          >
        {front[12] ? (
          <motion.div animate={{opacity : !flipped[12]? 1 : 0}}>
            <RiDoubleQuotesL size={30} color='var(--background-color)'/>
            <div className='widget-description-title'>Motivational quotes</div>
            <div className='widget-description'>Wants to have a motivational quote directly when you open the chrome page ? We found over 500k motivational quotes over the web, and allows you to see one at random every time you open a tab, in order to find inspiration all day</div>
            <Basic/>
          </motion.div>
        ) : (
          <motion.div animate={{opacity : flipped[12]? 1 : 0}} style={{ transform: "rotateY(180deg)" }}>
            <div className='video-container'>
              <video className='video-element' autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/videos/Demonstration quotes.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>    
          </motion.div>
        )}    
      </motion.div>
      </div>
      
      <div className='home-page-section-title'>What the community is saying</div>
      
    <div className='avis-positifs-big-container'>
      <motion.div className='avis-positifs-home' onMouseMove={handleMouseMove}>




          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Andrew_Carn.jpg" alt='twitter profil pic'/></div>                
              <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Andrew Carn</div>
                  <div className='avis-positifs-identifiant-profil'>@Andrew9811</div>
                </div>
              </div>
              <div className='avis-positifs-description'>Gurk really help me to plan my day, and see the planification very quickly. It has become an indispensable tool for my browser.</div>
              <div className='avis-positifs-date'>Feb 25 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Kam Hari.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Kam Hari</div>
                  <div className='avis-positifs-identifiant-profil'>@kamhari_b</div>
                </div>
              </div>
              <div className='avis-positifs-description'>What I love the most about gurk, is the possibility to customize as we want the home Page, with the widgets that are necessary for our daily productivity</div>
              <div className='avis-positifs-date'>Feb 20, 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Peter McKlin.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Peter McKlin</div>
                  <div className='avis-positifs-identifiant-profil'>@petermcklin91</div>
                </div>
              </div>
              <div className='avis-positifs-description'>The team is really reactive. I asked for a functionnality, they responded within the hour, and developed it within a week. Huge congratulations !</div>
              <div className='avis-positifs-date'>Feb 2, 2024</div>
            </div>
          </div>

      


        <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Laureen Tran.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Laureen Tran</div>
                  <div className='avis-positifs-identifiant-profil'>@laureentran3943</div>
                </div>
              </div>
              <div className='avis-positifs-description'>once you've tried it, you can't live without it! The customization is huge, and the combinations of widgets are infinite</div>
              <div className='avis-positifs-date'>Feb 19, 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Rick Rionad.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Rick Rionad</div>
                  <div className='avis-positifs-identifiant-profil'>@rickriord901</div>
                </div>
              </div>
              <div className='avis-positifs-description'>Of course, gurk is not perfect. But the team is very reactive to all the new bugs, that get solved within hours, so at the end, it is very pleasant to use.</div>
              <div className='avis-positifs-date'>Feb 21, 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Paloma Mayer.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Paloma Maye</div>
                  <div className='avis-positifs-identifiant-profil'>@palommaye99</div>
                </div>
              </div>
              <div className='avis-positifs-description'>If you want an extension that display all the infos you need on the newtab, then truly recomand you Gurk, it will have all the functionnalities you need.</div>
              <div className='avis-positifs-date'>Feb 14 2024</div>
            </div>
          </div>


        <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Antonio Delavera.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Antionio Delavera</div>
                  <div className='avis-positifs-identifiant-profil'>@antondelav</div>
                </div>
              </div>
              <div className='avis-positifs-description'>Huge congratulation to the few students that developed Gurk, it has to be a lot of work ! But the work is worthed, as it is just incredible !</div>
              <div className='avis-positifs-date'>Feb 9 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/Ariane Edgra.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>Ariane Edgra</div>
                  <div className='avis-positifs-identifiant-profil'>@edgrariane31</div>
                </div>
              </div>
              <div className='avis-positifs-description'>The calendar is really usefull to see all the day that is coming, and the other functionalties are also useful in a work day !</div>
              <div className='avis-positifs-date'>Feb 21 2024</div>
            </div>
          </div>

          <div className='card-container'>
            <div className='avis-positifs-container'>
              <div className='avis-positifs-profil-container'>
              <div className='image-profil-container'><img src="./images/John Felipe.jpg" alt='twitter profil pic'/></div>  
                <div className='avis-positifs-profil-text'>
                  <div className='avis-positifs-name-profil'>John Felipe</div>
                  <div className='avis-positifs-identifiant-profil'>@johnfelipedes</div>
                </div>
              </div>
              <div className='avis-positifs-description'>The thing I love the most about this extension is the fact that it transform your chrome page into something really aesthetic.</div>
              <div className='avis-positifs-date'>Feb 20 2024</div>
            </div>
          </div>




      </motion.div>
      </div>
        <div className='end-citation-home-page'>Turn your potential into progress</div>
        {/* <Avis/> */}
    </div>

  )
}

export default Home


function Premium() {
  return (
    <div className='plan-container-homepage'>
      <PremiumSvg size={"30px"} color="var(--background-color)"/>
      <div>Premium</div>
    </div>
  )
}

function Basic(){
  return (
    <div className='plan-container-homepage'>
      <BasicSvg size={"30px"} color="var(--background-color)"/>
      <div>Basic</div>
    </div>
  )
}

function Free(){
  return (
    <div className='plan-container-homepage'>
      <div>Free</div>
    </div>
  )
}

