import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../utils/Loader'
import Error from '../components/Error'
import Success from '../components/Success'
import axios from 'axios'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import { IoMailUnreadOutline } from 'react-icons/io5';
import { PiArrowLeftLight } from 'react-icons/pi';
import { loadStripe } from '@stripe/stripe-js';
import Cancel from '../components/Cancel';
import "../style/dashboard.scss"

const premium_monthly = process.env.REACT_APP_PREMIUM_MONTHLY;
const premium_annual = process.env.REACT_APP_PREMIUM_ANNUAL;
const basic_monthly = process.env.REACT_APP_BASIC_MONTHLY;
const basic_annual = process.env.REACT_APP_BASIC_ANNUAL;

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Dashboard() {
    const [page, setPage] = useState(999)
    const [user, setUser] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [emailValide, setEmailValide] = useState(false)
    const [abonnement, setAbonnement] = useState(null)
    const [compteGoogle, setCompteGoogle] = useState(false)
    const [compteGoogleInfo, setCompteGoogleInfo] = useState(null)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)
    const [showCompte, setShowCompte] = useState("")

    const [validationCode, setValidationCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [passwordToken, setPasswordToken] = useState("")
    const [error, setError] = useState("")
      
    let navigate = useNavigate();

    useEffect(() => {
      try{
        axios.get(`${process.env.REACT_APP_API_URL}/api/website/verify`, { withCredentials: true })
        .then((response)=>{
          console.log(response.data)
          if(!response.data.success){
            navigate("/logup")
          } else {
            fetchData()
          }
        })
        .catch((err)=>{
          console.log(err)
          navigate("/logup")
        })
      } catch (err) {
        console.log(err)
        navigate("/logup")
      }
    }, []);

    const fetchData = async () => {
      setPage(999)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/userSub`,{ withCredentials: true })
            const user = response.data;
            setUser(user)
            setName(user.name);
            setEmail(user.email)
            setEmailValide(user.emailValid)
            setAbonnement(getOffre(user.abonnement))
            setCompteGoogle(user.compteGoogle)
            setSubscriptionDetails(user.subscription)
            setPage(0)
        } catch (error) {
            console.log(error)
            navigate("/logup")
        }
    }
      
      const fetchGoogleAccountInfo = async () => {
        try {
    
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/googleToken`)
          // Fetch basic information about the Google account
          // const Response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.data.access_token}`);
          const accountResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/website/profilInfo/${response.data.access_token}`)
          setCompteGoogleInfo(accountResponse.data.accountInfo);
        } catch (err) {
          console.error(err);
        }
      }
      useEffect(() => {
        fetchGoogleAccountInfo();
      }, []);

    const getOffre = () => {
      if(!subscriptionDetails) return "Free"
      if(subscriptionDetails.plan.id === "free") return "Free"
      if(subscriptionDetails.plan.id === basic_monthly || subscriptionDetails.plan.id === basic_annual) return "Basic"
      if(subscriptionDetails.plan.id === premium_monthly || subscriptionDetails.plan.id === premium_annual) return "Premium"
    }

    const returnAcceuil = () => {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(0)
    }
    
    const handleChangeEmail = (email) => {
        if(email === user.email){
        setEmailValide(user.emailValid)
        } else {
        setEmailValide(false)
        }
        setEmail(email);
    }

    
  const sendEmailWithCode = async () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  
    // Vérifie si l'adresse e-mail est valide en utilisant l'expression régulière
    const isValid = emailPattern.test(email);
    const containsYopmail = /@yopmail\.com/i.test(email);
    if(!isValid || containsYopmail) return
    window.scrollTo(0, 0); // scroll to the top of the page
    setPage(999)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/email/send-code`, {email : email})
      if(response.data.success){
        setPage(1)
      }
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }

  
  const handleChangeValidationCode = (code) => {
    if (!isNaN(code) && code.length <= 6) {
      setValidationCode(code);
    }
  }

  const handleEnterCode = (code, status) => {
    if(code.length !== 6) return;
    if(status === "valider-email"){
      validerCodeEmail(code)
    } else if(status === "changer-password"){
      validerCodePassword(code);
    }
  }

  //-------------------------------------------------------------------------------------
  //-------------------------------------EMAIL------------------------------
  //-------------------------------------------------------------------------------------

  
  const validerCodeEmail = async (code) => {
    try {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/email/changeEmail`, { email : email, code : code})
      console.log(response.data)
      if(response.data.success){
        setPage(998)
        setEmailValide(true)
        user.emailValid = true
        user.email = email;
        setValidationCode("")
        setNewPassword("")
        setError("")
      } else {
        setError("wrong code")
        setPage(201)
      }
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }

  //-------------------------------------------------------------------------------------
  //-------------------------------------RESET PASSWORD------------------------------
  //-------------------------------------------------------------------------------------

  const resetPassword = ()=>{
    window.scrollTo(0, 0); // scroll to the top of the page
    setPage(999)
    axios.post(`${process.env.REACT_APP_API_URL}/api/website/password/send-code`, {email : email})
    .then((response)=>{
      if(response.data.success){
        setPage(101)
      }
    })
    .catch((err)=>{
      console.log(err)
      setPage(998)
    })
  }
  
  const validerCodePassword = async (code) => {
    try {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/password/askToken`, {email : email, code : code})
      if(response.data.success){
        setPage(102)
        setPasswordToken(response.data.token)
        setError("")
      } else {
        setError("wrong code")
        setPage(101)
      }
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }

  const handleNewPasswordConfirm = async ()=>{
    try {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/password/change`, {token : passwordToken, password : newPassword})
      if(response.data.success){
        setPage(997)
      }
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }
  

  //-------------------------------------------------------------------------------------
  //-------------------------------------DECONNEXION------------------------------
  //-------------------------------------------------------------------------------------

  const handleDeconnexion = async ()=>{
    await axios.get(`${process.env.REACT_APP_API_URL}/api/website/logout`, { withCredentials: true })
    window.scrollTo(0, 0); // scroll to the top of the page
    navigate("/");
    window.location.reload();
  }  

  //-------------------------------------------------------------------------------------
  //-------------------------------------Comptes GOOGLE------------------------------
  //-------------------------------------------------------------------------------------

    const SCOPES = [
        // 'https://www.googleapis.com/auth/fitness.activity.read',
        // 'https://www.googleapis.com/auth/fitness.body.read',
        // 'https://www.googleapis.com/auth/fitness.location.read',
        // 'https://www.googleapis.com/auth/fitness.nutrition.read',
        // 'https://www.googleapis.com/auth/fitness.blood_glucose.read',
        // 'https://www.googleapis.com/auth/fitness.blood_pressure.read',
        // 'https://www.googleapis.com/auth/fitness.body_temperature.read',
        // 'https://www.googleapis.com/auth/fitness.reproductive_health.read',
        // 'https://www.googleapis.com/auth/fitness.oxygen_saturation.read',
        // 'https://www.googleapis.com/auth/fitness.sleep.read',
        // 'https://www.googleapis.com/auth/fitness.heart_rate.read',
        'https://www.googleapis.com/auth/calendar',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
        ].join(' ');

    const handleAssocier = () => {
        // Redirigez l'utilisateur vers l'URL d'authentification Google
        const authUrl = 'https://accounts.google.com/o/oauth2/auth?' +
        'scope=' + SCOPES + "&"+// Ajoutez les scopes nécessaires
        'response_type=code&' + // Demandez le code d'autorisation
        'access_type=offline&' + // Autorisez l'accès hors ligne
        'client_id=' + process.env.REACT_APP_GOOGLE_CLIENT_ID + '&' +
        `redirect_uri=${process.env.REACT_APP_PUBLIC_URL}/google`; // L'URL de redirection doit correspondre à la configuration de votre application Google API Console
        
        // Open the Google authentication URL in a new window
        window.location.href = authUrl;
    };

  const handleDesassocier = async ()=>{
    try{
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/google/delete-tokens`)
      if(response.data.success){
        setCompteGoogle(false)
        setCompteGoogleInfo(null)
      }
      setPage(997)
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }

  const handlePreviewAssocier = () => {
    window.scrollTo(0, 0); // scroll to the top of the page
    setPage(401)
  }

  //-------------------------------------------------------------------------------------
  //-------------------------------------Subscription------------------------------
  //-------------------------------------------------------------------------------------

  const handleChangeSubscription = () => {
    window.scrollTo(0, 0); // scroll to the top of the page
    navigate("/pricing")
  }
   
  
  const changePaymentDetails = async () => {
    try{
      setPage(998)
        const stripe = await stripePromise;
        // Get Checkout Session ID from your server
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/create-checkout-session`, {});

        const { sessionId } = response.data;

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result.error.message);
            setPage(999)
        } else {
            setPage(997)
        }

    } catch (err) {
        console.log(err)
        setPage(999)
    }
    };

  const deleteAccount = async () => {
    setPage(501)
  }

  const handleDeleteConfirm = async () => {
    try {
      window.scrollTo(0, 0); // scroll to the top of the page
      setPage(999)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/delete-account`)
      if(response.data.success){
        await axios.get(`${process.env.REACT_APP_API_URL}/api/website/logout`, { withCredentials: true })
        navigate("/")
        window.location.reload();
      }
    } catch (err){
      console.log(err)
      setPage(998)
    }
  }

  const coupDePression = async () => {
    window.scrollTo(0, 0); // scroll to the top of the page
    setPage(301)
  }

  console.log(subscriptionDetails)

  return (
    <div className='dashboard-container'>
        {page===0 && 
    <div className='dashboard-content-container'>
      <div className="header-settings">
        <div className="title-settings">Dashboard</div>
        <div className="description-settings">See all your profile</div>

        <div className="setting-separator"></div>

        <div className="settings-widget-container-profil">
          <div className="widget-settings-title">Name</div>
          <input
            className="profil-input-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="setting-separator"></div>

        
        <div className="settings-widget-container-profil" style={{marginBottom : "0px"}}>
          <div className="widget-settings-title">Email</div>
          <input
            className="profil-input-email"
            value={email}
            onChange={(e) => handleChangeEmail(e.target.value)}
          />
        </div>

          <motion.div className='validate-email-button-container' style={{overflow : "hidden"}} initial={{height : emailValide? "0px" : ""}} animate={{height : emailValide? "0px" : ""}}>
            <motion.div whileHover={{scale : 1.05}} onClick={()=>sendEmailWithCode()} className='validate-email-button'>Verify your email</motion.div>
          </motion.div>

        <div className="setting-separator"></div>

        <div className="settings-widget-container-profil">
          <div className="widget-settings-title">Associated Accounts</div>
        </div>
        <div className="profil-comptes-container">
          {/* Google */}
          <div className="profil-compte-container" onClick={()=>showCompte==="google"? setShowCompte("") : setShowCompte("google")} style={{borderColor : compteGoogle!==false? "#00AA00" : "#AA0000"}}>
            {/* Logo de google */}
            <div className="name-container">
              <svg viewBox="-0.5 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Color-" transform="translate(-401.000000, -860.000000)">
                      <g id="Google" transform="translate(401.000000, 860.000000)">
                        <path d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24" id="Fill-1" fill="#FBBC05"></path>
                        <path d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333" id="Fill-2" fill="#EB4335"></path>
                        <path d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667" id="Fill-3" fill="#34A853"></path>
                        <path d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24" id="Fill-4" fill="#4285F4"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div>Google</div>
            </div>
            {/* Fleche */}
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-down-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke={compteGoogle? "#00AA00" : "#AA0000"} fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M7 7l10 10" />
              <path d="M17 8l0 9l-9 0" />
            </svg>
          </div>
        </div>
            
  
        <motion.div className='informations-account' initial={{height : showCompte==="google"? "" : "0px"}} animate={{height : showCompte==="google"? "" : "0px"}}>
          {compteGoogleInfo? (
            <>
            <div className='information-profil-container'>
              <div className='information-profil-image-container'><img style={{width : "100%", height : "100%"}} src={compteGoogleInfo.picture} alt="profil"/></div>
              <div>
                <div>{compteGoogleInfo.name}</div>
                <div>{compteGoogleInfo.email}</div>
              </div>
            </div>
            <div>
              <motion.div whileHover={{scale : 1.05}} className='button-delete-google-account' onClick={()=>handleDesassocier()}>Unlink that account</motion.div>
            </div>
            </>
          ) : (
            <motion.div whileHover={{scale : 1.05}} className='associer-compte-profil-dashboard' onClick={()=>handlePreviewAssocier()}>Associate an account</motion.div>
          )}
        </motion.div>

        <div className="setting-separator"></div>

        <div className="settings-widget-container-profil">
          <div className="widget-settings-title">Subscription</div>
            <div className="abonnement-profil">{abonnement}</div>
        </div>

        <div className="setting-separator"></div>

        {/* SECTION DU PAIEMENT */}

        {subscriptionDetails && subscriptionDetails.plan.id !== "free" && (
          <>
          <InformationContainer titre="status" description={subscriptionDetails.status}/>
          {subscriptionDetails.created!==null && <InformationContainer titre="Subscription started the" description={format(new Date(subscriptionDetails.created*1000), 'dd/MM/yyyy')}/>}
          {subscriptionDetails.current_period_start!==null && <InformationContainer titre="Start of the current period the" description={format(new Date(subscriptionDetails.current_period_start*1000), 'dd/MM/yyyy')}/>}
          {subscriptionDetails.current_period_end!==null && <InformationContainer titre="End of the current period the" description={format(new Date(subscriptionDetails.current_period_end*1000), 'dd/MM/yyyy')}/>}
          {!subscriptionDetails.cancel_at_period_end && <InformationContainer titre="Price to pay next time" description={subscriptionDetails.plan.amount/100 + subscriptionDetails.plan.currency}/>}
          {!subscriptionDetails.cancel_at_period_end && <InformationContainer titre="Billing" description={subscriptionDetails.plan.interval==="year"? "annual" : subscriptionDetails.plan.interval==="month"? "monthly" : "error"}/>}
          {subscriptionDetails.trial_start!==null && <InformationContainer titre="Free trial started on" description={format(new Date(subscriptionDetails.trial_start*1000), 'dd/MM/yyyy')}/>}
          {subscriptionDetails.canceled_at!==null && <InformationContainer titre="Subscription cancelled the" description={format(new Date(subscriptionDetails.canceled_at*1000), 'dd/MM/yyyy')}/>}
          {subscriptionDetails.cancel_at!==null && <InformationContainer titre="End of the subscription the" description={format(new Date(subscriptionDetails.cancel_at*1000), 'dd/MM/yyyy')}/>}
          {subscriptionDetails.ended_at!==null && <InformationContainer titre="Subscription ended the" description={format(new Date(subscriptionDetails.ended_at*1000), 'dd/MM/yyyy')}/>}
          <InformationContainer titre="Automatic renew" description={subscriptionDetails.cancel_at_period_end? "disabled" : "enabled"}/>
          </>
        )}

        {/* TOUS LES BOUTONS DU BAS */}

        <div className="reset-container-dashboard">
          <motion.div className="reset-button-dashboard" onClick={(e)=>resetPassword()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Reset Password</motion.div>       
          <motion.div className="reset-button-dashboard" onClick={(e)=>deleteAccount()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Delete account</motion.div>       
          <motion.div className="reset-button-dashboard" onClick={(e)=>handleDeconnexion()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Logout</motion.div>       
          {(subscriptionDetails && !(subscriptionDetails.plan.id==="free")) && 
          <>
          <motion.div className="reset-button-dashboard" onClick={(e)=>handleChangeSubscription()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Change Subscription</motion.div>       
          <motion.div className="reset-button-dashboard" onClick={(e)=>changePaymentDetails()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Update Payment method</motion.div>       
          <motion.div className="reset-button-dashboard" onClick={(e)=>coupDePression()} whileHover={{scale : 1.05, cursor:"pointer"}} transition={{duration : 0.4}}>Cancel Subscription</motion.div>       
          </>
          }
        </div>
            
            
      </div>

    </div>
        }

        {/* RESET PASSWORD */}

        {page===101 && (
          <div className='error-confirm-container flex-column-sa'>
            <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
              <div className='flex'>A validation mail has been send to {email}</div>
              <div className='flex'><IoMailUnreadOutline size={100} color="var(--background-color)"/>
              <div><input className='validation-code-email-input' placeholder="Validation code" value={validationCode} onChange={(e)=>handleChangeValidationCode(e.target.value)}/></div>
            </div>
            <motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleEnterCode(validationCode, "changer-password")}>Confirm the code</motion.div>
          </div>
        )}

        {page===102 && (
          <div className='error-confirm-container flex-column-sa'>
            <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <div className='reset-mdp-text'>Enter you new password</div>
            <input type="password" className='newpassword-input' value={newPassword} placeholder="New password" onChange={(e)=>setNewPassword(e.target.value)}/>
            <motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleNewPasswordConfirm()}>Confirm the password</motion.div>
          </div>
        )}

        {/* CONFIRM EMAIL */}

        {page===201 && (
          <div className='error-confirm-container flex-column-sa'>
          <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <div className='flex'>A validation mail has been send to {email}</div>
            <div className='flex'><IoMailUnreadOutline size={100} color="var(--background-color)"/>
            <div><input className='validation-code-email-input' placeholder="Validation code" value={validationCode} onChange={(e)=>handleChangeValidationCode(e.target.value)}/></div>
            {error!=="" && <div className='error-validation-code'>{error}</div>}
          </div>
          <motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleEnterCode(validationCode, "valider-email")}>Confirm the code</motion.div>
        </div>
        )}

        {/* CANCEL EMAIL */}

        {page===301 && (
          <div className='error-confirm-container'><Cancel subscription={subscriptionDetails} returnAcceuil={returnAcceuil} changePage={setPage}/></div>
        )}

        {/* GOOGLE */}

        {page===401 && (
          <div className='error-confirm-container flex-column-sa'>
            <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <div className='warning-google-account-title'>Before to link your Google Account</div>
            <div>When clicking on this button, a popup will appears, and will ask you to share sensitive data about your Google Account. <span className='not-store-data'>WE WILL NOT STORE ANY OF THAT DATA, WE ARE ONLY GOING TO DISPLAY IT.</span></div>
            <div>You have the choice to share differents fields, you <span className='not-store-data'>DO NOT NEED</span> to share them all, <span className='not-store-data'>BUT</span> <br/>-If you plan to have the Calendar widget, please let us access your Google Calendar.<br/> Remember, we <span className='not-store-data'>WILL NOT STORE THE DATA.</span><br/>Gurk (this app) use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy'>Google API Services User Data Policy</a>, including the Limited Use requirements</div>
            <div><motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleAssocier()}>Link your Google account</motion.div></div>
          </div>
        )}

        {/* DELETE ACCOUNT */}
        {page===501 && (
          <div className='error-confirm-container flex-column-sa'>
            <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAcceuil()} className='return-arrow-dashboard'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <div className='warning-google-account-title'>Are you sure you want to delete your account?</div>
            <div>By clicking on the button below, you will delete all your data and your account. <span className='not-store-data'>ALL THE INFORMATIONS WILL BE DELETED, INCLUDING YOUR SUBSCRIPTIONS, WITHOUR ANY POSSIBILITY OF REFUND</span></div>
            <div><motion.div whileHover={{scale : 1.05}} className='profil-button' onClick={()=>handleDeleteConfirm()}>Delete my account</motion.div></div>
          </div>
        )}

        {page===997 && <div className='error-confirm-container'><Success message={true} returnAcceuil={returnAcceuil}/></div>}
        {page===998 && <div className='error-confirm-container'><Error returnAcceuil={returnAcceuil}/></div>}
        {page===999 && <Loader width={40} color="var(--background-color)"/>}
    </div>
  )
}

export default Dashboard



function InformationContainer (props) {
  return (
    <>
    <div className="settings-widget-container-profil">
      <div className="widget-settings-title">{props.titre}</div>
      <div className="abonnement-profil">{props.description}</div>
    </div>
    <div className="setting-separator"></div>
    </>
  )
}