import React, { useEffect, useRef, useState } from 'react'
import {motion} from 'framer-motion'
import "../style/logup.scss"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loader from '../utils/Loader'
import Error from '../components/Error'

axios.defaults.withCredentials = true;

function Logup() {

    const [login, setLogin] = useState(true)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)

    const [page, setPage] = useState(999)

    let navigate = useNavigate();

    useEffect(() => {
      try{
        setPage(999)
        axios.get(`${process.env.REACT_APP_API_URL}/api/website/verify`, { withCredentials: true })
        .then((response)=>{
          if(response.data.success){
            navigate("/dashboard")
          } else {
            setPage(0)
          }
        })
        .catch((err)=>{
          console.log(err)
          setPage(998)
        })
      } catch (err) {
        console.log(err)
        setPage(998)
      }
    }, []);
    
    
    const handleLogup = async () => {
      
        if(login){
          if(email !== "" && password !== ""){
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if(!emailRegex.test(email)) {
              setError("Invalid email format");
              return;
            }
            setPage(999)
            setError("")
            try {
              const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/login`, {
                email : email,
                password : password,
              })
              if(res.data.success === false){
                setError(res.data.message)
              }else{
                window.location.href = "/dashboard"
              }
            } catch (error) {
              setPage(998)
              console.log(error)
              setError("Please try again later")
            }
            setPage(0)
          }else{
            setError("Veuillez remplir tous les champs")
          }
        } else {
          if(name!== "" && email !== "" && password !== "" && passwordConfirm !== ""){
            if(password === passwordConfirm){
              const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if(!emailRegex.test(email)) {
                setError("Invalid email format");
                return;
              }
              setLoading(true)
              setError("")
              try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/website/signup`, {
                  name : name,
                  email : email,
                  password : password,
                })
                if(res.data.error){
                  setError(res.data.message)
                }else{
                  console.log(res.data)
                  window.location.href = "/dashboard"
                }
              } catch (error) {
                setError(error.message)
              }
              setLoading(false)
            }else{
              setError("Les mots de passe ne correspondent pas")
            }
          }else{
            setError("Veuillez remplir tous les champs")
          }
        }
      }
     const  handleReturnAcceuil = () => {
        setPage(0)
      }

  return (
    <div className='login-page'>
      {page===0 && (
        
        <div className='login-container'>

            <motion.div className='login-element-container' animate={{height : login? "0px" : ""}} initial={{height : login? "0px" : ""}}>
                <div className='login-title'>Name</div>
                <input className='login-input' type="text" value={name} onChange={e => setName(e.target.value)}/>
            </motion.div>

            <div className='login-element-container'>
                <div className='login-title'>Email</div>
                <input className='login-input' type="text" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>

            <div className='login-element-container'>
                <div className='login-title'>Password</div>
                <input className='login-input' type="password" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>

            <motion.div className='login-element-container' animate={{height : login? "0px" : ""}} initial={{height : login? "0px" : ""}}>
                <div className='login-title'>Confirm Password</div>
                <input className='login-input' type="password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
            </motion.div>
            
            <motion.div className='login-button' whileHover={{scale : 1.05}} onClick={()=>{handleLogup()}}>{login? "login" : "signup"}</motion.div>
            <motion.div className='login-error' animate={{height : error===""? "0px" : "", marginTop : error===""? "0px" : "10px", opacity : error===""? 0 : 1}}>{error}</motion.div>
            <div className='login-switch' onClick={()=>{setLogin(!login)}}>{login? "Vous n'avez pas de compte " : "Vous avez déjà un compte ? "}<strong>{login? "S'inscrire" : "Se connecter"}</strong></div>
            </div>
      )}
      {page===998 && (
        <Error returnAcceuil={handleReturnAcceuil}/>
      )}

      {page===999 && (
        <Loader width={30} color="var(--background-color)"/>
      )}
    </div>
  )
}

export default Logup