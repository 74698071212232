import React from 'react';
import {motion} from "framer-motion"
import "../style/pricing/payment2.scss"
import { IoArrowBackOutline } from 'react-icons/io5';
import { AdvantagesSubscription, AvisPositifs } from './Payment';



const premium_monthly = process.env.REACT_APP_PREMIUM_MONTHLY;
const premium_annual = process.env.REACT_APP_PREMIUM_ANNUAL;
const basic_monthly = process.env.REACT_APP_BASIC_MONTHLY;
const basic_annual = process.env.REACT_APP_BASIC_ANNUAL;

const Payment2 = (props) => {

    const getOffre = () => {
        if(props.planId === "free") return "Free"
        if(props.planId === basic_monthly || props.planId === basic_annual) return "Basic"
        if(props.planId === premium_monthly || props.planId === premium_annual) return "Premium"
      }
  
      const getFrequence = () => {
        if(props.planId === "free") return ""
        if(props.planId === basic_monthly || props.planId === premium_monthly) return "Monthly"
        if(props.planId === basic_annual || props.planId === premium_annual) return "Annual"
      }
  
      const getFacture = () => {
        if(props.planId === "free") return "Free"
        if(props.planId === basic_monthly) return "3.99€/Mo"
        if(props.planId === basic_annual) return "36€/Year"
        if(props.planId === premium_monthly) return "15.99€/Mo"
        if(props.planId === premium_annual) return "144€/Year"
      }
      console.log(props.currentPlan)
      const getRecap = () => {
        if(props.planId === "free") return "You are not supposed to be here"
        if (props.currentPlan === "free") return "You are not supposed to be here"
        if((props.currentPlan === basic_monthly && props.planId === basic_annual) || (props.currentPlan === premium_monthly && props.planId === premium_annual)) return "You will be charged immediatly for the begining of an annual subscription, minus the price you paid at the begining of the month"
        if((props.currentPlan === basic_annual && props.planId === basic_monthly) || (props.currentPlan === premium_annual && props.planId === premium_monthly)) return "At the end of the current period, your annual subscription will not be renewed and a monthly subscription will begin"
        if((props.currentPlan === basic_monthly && (props.planId === premium_monthly || props.planId === premium_annual)) || (props.currentPlan === basic_annual && (props.planId === premium_annual || props.planId === premium_monthly))) return "You will be charged immediatly for the begining of a premium subscription"
        if((props.currentPlan === premium_monthly && (props.planId === basic_monthly || props.planId === basic_annual)) || (props.currentPlan === premium_annual && (props.planId === basic_annual || props.planId === basic_monthly))) return "At the end of the current period, your premium subscription will not be renewed and a basic subscription will begin"
        else return "An error occured"
    }

    return (
        <div className='change-payment-container'>
            <div className='confirmation-page'>

            <div className='header-payment-form'>
                <motion.div whileHover={{scale : 1.1}} className='arrow-back-container' onClick={()=>props.returnAcceuil()}><IoArrowBackOutline/></motion.div>
                <div>Change for {getOffre()} ({getFrequence()})</div>
            </div>
            <div>
                {getRecap()}
            </div>
            <motion.div onClick={()=>props.handleChangeSubscription()} className='button-submit-change-subscription'>
                Change to {getOffre()} ({getFrequence()})
            </motion.div>
            </div>
            <div className='recapitulatif-change-payment'>
                <div className='recapitulatif-title'>{getOffre()} ({getFrequence()})</div>
                <div className='recapitulatif-price'>Or {getFacture()}</div>
                <div className='separator-change-payment'></div>
                <AdvantagesSubscription getOffre={getOffre} getFrequence={getFrequence}/>
                <div className='separator-change-payment'></div>
                <AvisPositifs/>
            </div>
        </div>
    );
};

export default Payment2;