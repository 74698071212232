import React, { useContext, useState } from 'react'
import { IoChevronDown } from "react-icons/io5";
import {motion} from 'framer-motion'
import "../style/faq.scss"

const FAQ = [
    {"Question" : "What is Gurk?", "Réponse" : "Gurk is a chrome extension that transforms your home page into a customizable dashboard, allowing you to see at a glance all the important information for your day."},
    {"Question" : "I don't understand how to use Gurk...", "Réponse" : "Don't worry, there's a lot of functionality to understand before you get started. We've put together a tutorial on Youtube, in English, French and Spanish, which explains how to use Gurk, and how to customize it (just type gurk on Youtube)."},
    {"Question" : "Do you have any tips?", "Réponse" : "The main tip is that where there's no scroll bar, there is one! On a more serious note, we've decided to remove the majority of scrollbars, as they serve no useful purpose other than to disfigure certain parts of the application! Next, don't hesitate to check if the settings have been saved correctly if you see that a setting isn't being applied, or to reopen a new tab - sometimes gurk can't change all the page's behavior without restarting. If the bug persists after 4 or 5 tabs, then it's definitely a bug, and we invite you to report it to gurk.contact@gmail.com."},
    {"Question" : "How does it works ?", "Réponse" : "Gurk has been entirely coded in javascript, for client-side optimization and rapid execution. Data (when the extension needs it) is retrieved via APIs, and stored locally to make the experience even faster!"},
    {"Question" : "Who created Gurk ?", "Réponse" : "Gurk was created by a team of 3 students, who decided to embark on this project to help people better organize their day, and make better use of their time. We decided to embark on this project to challenge ourselves, and to help people! We're funding the project out of our own pockets, and even though it's costing us a bit of money, for the time being, we just need to save (more or less) in our student bugets to keep it going, so the servers should keep running for a while!"},
    {"Question" : "Why do some features have to be paid for?", "Réponse" : "At the development stage, every feature was tested and developed with the same attention to detail, but some features, particularly those that rely on external sites (such as Calendar, Google Fit and the Stock Exchange), have a cost, and for this reason they are only available on a paid plan, to avoid the project ending in financial failure in the first few months. However, even with these features in paid plans, we are not currently profitable in the long term, but the deficit is less than if everything were free, which means we can continue to develop the project."},
    {"Question" : "I've found a bug or have a suggestion. How can I let you know?", "Réponse" : "If you've found a bug, or if you have a suggestion, don't hesitate to contact us at gurk.contact@gmail.com. We're always ready to listen to our users, and we do our best to respond to all requests as quickly as possible (between 1 a.m. and 4 p.m. depending on your location)."},
    {"Question" : "What data do you collect?", "Réponse" : "We currently store the following data on Gurk's servers: name, email address, password, subscription information and Google account links. We do not store any data that is not essential to the proper functioning of the application. For example, we do not have access to data such as the user's settings, to-do list, or other information relating to the various widgets available on the application."},
    {"Question" : "Where is the data stored ?", "Réponse" : "Gurk's data is stored on our own servers in Switzerland, so we have total control over who has access to it."},
    {"Question" : "How can I get in touch with you?", "Réponse" : "You can contact us at gurk.contact@gmail.com and tell us what you think of Gurk. We're always delighted to receive feedback, whether positive or negative."},
    {"Question" : "How can I support you?", "Réponse" : "If you'd like to support us, you can upgrade to a paid plan, or contact us to see if you can help in some other way. We're always on the lookout for partnerships, or people who can help us develop the project."},
    {"Question" : "How can I help you develop Gurk?", "Reponse" : "Gurk is currently still in its initial phase, and is not well known to the general public. To keep the project going, we're going to need users, otherwise we'll end up stopping the project if we see no one is interested. So, the best way to help us develop the project is to share Gurk with your friends, or give us feedback on the chrome web store, or on social networks. All help is welcome, and we thank you in advance."},
    {"Question" : "What's coming in future versions?", "Réponse" : "For the moment, new widgets are in the pipeline. We're aiming to add a Main Focus below the name and in the To Do List widget, implement a percentage of completion for the day, add a screen time widget, add a widget that displays different news items, as well as a widget that lets you chat with ChatGPT directly from Gurk! From a customization point of view, we want to add the ability to limit access to certain sites for a certain time per day, to help our users reduce their use of non-productive sites (notably social networks), by blocking the instagram reels page for example. We also want to go further in customizing the background, with the ability to choose the brightness and blur of the image. Of course, if you have any other ideas, don't hesitate to send us an e-mail at gurk.contact@gmail.com."},
    {"Question" : "Why Gurk?", "Réponse" : "Honestly, we ask ourselves that every day too! (No, it actually stands for Google Universal Research Konsole, since the aim is to have all Google's tools at your fingertips)."},
    {"Question" : "Why aren't some features available in my country?", "Réponse" : "Currently we are not aware that some features are not available in some countries, but if you are having problems, this is probably due to your country's web policy, and, even if you can email us at gurk.contact@gmail.com, chances are we can't do anything."},
    {"Question" : "I'd like you to integrate my API as a widget!", "Réponse" : "If you have an API that you'd like to see integrated into Gurk, don't hesitate to send us an e-mail at gurk.contact@gmail.com, and we'll see if it's possible! (but probably yes)."},
    {"Question" : "Why isn't my language available yet?", "Réponse" : "We're currently working on translating Gurk into other languages. At the moment, translations are done by team members directly into the languages they know - unfortunately, we don't know every language in the world. However, if you absolutely must have Gurk in your language, don't hesitate to send us an e-mail, and we'll do our best to translate Gurk into your language as quickly as possible. If you'd like to help us translate Gurk into your language yourself, please contact us at gurk.contact@gmail.com."}
]

function Faq() {
    return (
    <div className='about-container'>
        <div className='header-settings-faq'>
            <div className='title-settings-faq'>FAQ</div>
            <div className='description-settings'>To know more about us</div>
        </div>

        <div className='setting-separator'></div>

        {FAQ.map((item)=> <Question question={item}/>)}
    </div>
  )
}

function Question(props){
    const [isOpen, setIsOpen] = useState(false)
    return(
        <div className='question-container-faq'>
            <motion.div className='title-container-about-faq' onClick={()=>setIsOpen(!isOpen)} initial={{marginBottom : "0px"}} animate={{marginBottom : isOpen? "10px" : "0px"}}>
                <div className='title-question-about-faq'>{props.question.Question}</div>
                <motion.div className='chevron-about-container-faq' animate={{rotateZ : isOpen? 180 : 0}}><IoChevronDown size={"2em"} /></motion.div>
            </motion.div>
            <motion.div className='description-settings-faq' initial={{height : "0px"}} animate={{height : isOpen? "" : "0px"}}>
                {props.question.Réponse}
            </motion.div>

            <div className='setting-separator'></div>
        </div>
    )
}

export default Faq