import React from 'react'

function PremiumSvg(props) {
  return (
<svg width={props.size} height={props.size} viewBox="0 0 881 882"  xmlns="http://www.w3.org/2000/svg">
    <title>Cette fonctionnalitée est réservée aux utilisateurs de Premium</title>
    <g transform="translate(-1760 -797)">
        <path d="M1794.5 1238C1794.5 1013.5 1976.27 831.5 2200.5 831.5 2424.73 831.5 2606.5 1013.5 2606.5 1238 2606.5 1462.5 2424.73 1644.5 2200.5 1644.5 1976.27 1644.5 1794.5 1462.5 1794.5 1238Z" stroke={props.color} strokeWidth="68.75" strokeMiterlimit="8" fill="none" fillRule="evenodd"/>
        <path d="M1949 1408 1949 1060 2297 1408Z" fill={props.color} fillRule="evenodd"/>
        <path d="M0 348 0 0 348 348Z" fill={props.color} fillRule="evenodd" transform="matrix(-1 0 0 1 2452 1060)"/>
        <path d="M0 251 0 0 251 251Z" fill={props.color} fillRule="evenodd" transform="matrix(-1 0 0 1 2200 1157)"/>
        <path d="M2200 1408 2200 1157 2451 1408Z" fill={props.color} fillRule="evenodd"/>
    </g>
</svg>
  )
}

export default PremiumSvg