import React, { useState } from 'react'
import {motion} from 'framer-motion'
import "../style/pricing/offres.scss"

const accessFree = ["Favorites", "To do List", "Notes", "Reminders"]
const accessBasic = ["Unsplash pictures for background", "Google Calendar (max 5 calendars)", "Google Fit", "Daily Fun Fact", "Motivational Quotes"]
const accessPro = ["Stock Market", "Maps", "Google Calendar (unlimited calendars)"]


const premium_monthly = process.env.REACT_APP_PREMIUM_MONTHLY;
const premium_annual = process.env.REACT_APP_PREMIUM_ANNUAL;
const basic_monthly = process.env.REACT_APP_BASIC_MONTHLY;
const basic_annual = process.env.REACT_APP_BASIC_ANNUAL;


function Offres(props) {
    const [annual, setAnnual] = useState(false)

    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)

    const [page, setPage] = useState(0)

    const [subscription, setSubscription] = useState(props.currentSubscription)

    const changePlan = (plan) => {
        //trouver l'id du plan : 
        let planId = "free"
        if(plan === "basic") { planId = annual? basic_annual : basic_monthly}
        if(plan === "premium") { planId = annual? premium_annual : premium_monthly}
        //si la subscription est celle de l'utilisateur, affiche les détails
        if(subscription.plan_id === planId) {
            props.showDetails()
            return
        }
        console.log(planId)
        //si la subscription est gratuit on souscrit à un plan
        if(planId === "free") {
            console.log("ici ?")
            props.cancelPlan()
            return
        }
        if(subscription.plan_id === "free") {
            props.subscribePlan(planId)
            return
        }

        //si on a cliqué sur free et qu'on est pas en free, on annule le plan
        
        //sinon on change de plan
        props.changePlan(planId)
        return
    }

  return (
    <div>
      <div className='billed-choose-container'>
        <motion.div className='billed-monthly'animate={{color : !annual? "#000000" : "#777777", borderBottom : !annual?"3px solid #000000" : "1px solid #777777"}} onClick={()=>setAnnual(false)}>Monthly</motion.div>
        <motion.div className='billed-annual' animate={{color : annual? "#000000" : "#777777", borderBottom : annual?"3px solid #000000" : "1px solid #777777"}} onClick={()=>setAnnual(true)}>Annual</motion.div>  
      </div>  
      <div className='offres-container'>
        
        <motion.div className='offre-container' animate={{boxShadow : hover1? "5px 5px 10px #AAAAAA" : "0px 0px 0px #AAAAAA"}} onHoverStart={()=>setHover1(true)} onHoverEnd={()=>setHover1(false)}>
          <div className='offre-header'>Free</div>
          <div className='offre-bas'>

            {accessFree.map((benefice, index)=>(
              <div key={index} className='benefice-container'><Check color="green"/><div>{benefice}</div></div>
            ))}

            <div className='barre-transversale'></div>

            <div className='prix'>Free</div>
            <div className='prelevement'>No credit card needed</div>
            <motion.div className='button-price' whileHover={{scale : 1.05}} onClick={()=>{changePlan("free")}}>{(subscription.plan_id===basic_monthly || subscription.plan_id ===basic_annual || subscription.plan_id===premium_monthly|| subscription.plan_id===premium_annual)? "Return to free" : "Current Plan"}</motion.div>
          </div>
        </motion.div>

        <motion.div className='offre-container' animate={{boxShadow : hover2? "5px 5px 10px #AAAAAA" : "0px 0px 0px #AAAAAA"}} onHoverStart={()=>setHover2(true)} onHoverEnd={()=>setHover2(false)}>
          <div className='offre-header'>Basic</div>
          <div className='offre-bas'>

            {accessBasic.map((benefice, index)=>(
              <div key={index} className='benefice-container'><Check color="green"/><div>{benefice}</div></div>
            ))}
            <div className='benefice-container'><Check/><div>7 days trial</div></div>

            <div className='barre-transversale'></div>

            <div className='prix'>{annual? "2.99€/Mo" : "3.99€/Mo"}</div>
            <div className='prelevement'>{annual? "Billed 36€ each year": "Billed each month"}</div>
            <motion.div whileHover={{scale : 1.05}} className='button-price' onClick={()=>changePlan("basic")}>{(subscription.plan_id===basic_monthly && annual)? "Switch to annual" : (subscription.plan_id===basic_annual && !annual)? "Switch to monthly" : ((subscription.plan_id===basic_monthly && !annual) || (subscription.plan_id===basic_annual && annual))? "Current Plan" : "Try Basic"}</motion.div>
          </div>
        </motion.div>


        <motion.div className='offre-container' animate={{boxShadow : hover3? "5px 5px 10px #AAAAAA" : "0px 0px 0px #AAAAAA"}} onHoverStart={()=>setHover3(true)} onHoverEnd={()=>setHover3(false)}>
          <div className='offre-header'>Premium</div>
          <div className='offre-bas'>

            <div className='benefice-container'><Check/><div>All the advantages of Basic</div></div>
            {accessPro.map((benefice, index)=>(
              <div key={index} className='benefice-container'><Check color="green"/><div>{benefice}</div></div>
            ))}
            <div className='benefice-container'><Check/><div>7 days trial</div></div>

            <div className='barre-transversale'></div>

            <div className='prix'>{annual? "11.99€/Mo" : "15.99€/Mo"}</div>
            <div className='prelevement'>{annual? "Billed 144€ each year": "Billed each month"}</div>
            <motion.div className='button-price' whileHover={{scale : 1.05}} onClick={()=>changePlan("premium")}>{(subscription.plan_id===premium_monthly && annual)? "Switch to annual" : (subscription.plan_id===premium_annual && !annual)? "Switch to monthly" : ((subscription.plan_id===premium_monthly && !annual) || (subscription.plan_id===premium_annual && annual))? "Current plan" : "Try premium"}</motion.div>
          </div>
        </motion.div>

      </div>
      </div>
  )
}

export default Offres




function Check(){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M5 12l5 5l10 -10" />
      </svg>
    )
  }
  