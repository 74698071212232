import React from 'react'
import { motion } from 'framer-motion'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { PiArrowLeftLight } from 'react-icons/pi';

function Error(props) {
  return (
    <div className='thanks-purchage-container'>
    <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>props.returnAcceuil()} className='arrow-back-container-thanks-page'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
    <IoIosCloseCircleOutline size={100} color='red' strokeWidth="1px" />
    <div className='thanks-text'>Oups ! A problem append</div>
    <div className='thanks-explanation'>Try later, or contact our webteam at gurk.contact@gmail.com</div>
  </div>
  )
}

export default Error