import React from 'react'
import { motion } from 'framer-motion'
import { PiArrowLeftLight } from 'react-icons/pi'
import { format } from 'date-fns'
import "../style/pricing/subscription.scss"

function Subscription(props) {
    console.log(props.subscription)
  return (
    <div className='subscription-details-container'>
        {props.subscription !== null  && (
        <>
            <div className='subscription-details-header-container'>
        <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>props.returnAcceuil()} className='arrow-back-container-details'><PiArrowLeftLight size={30} strokeWidth="10px"/></motion.div>
          <div className='subscription-details-header'>Details of your subscription</div>
        </div>
        <div className='subscription-details-content-container'>
          <div>Status : {props.subscription.status}</div>
          <div>Subscription started the : {format(new Date(props.subscription.created*1000), 'dd/MM/yyyy')}</div>
          <div>Start of the current period the : {format(new Date(props.subscription.current_period_start*1000), 'dd/MM/yyyy')}</div>
          <div>End of the current period the : {format(new Date(props.subscription.current_period_end*1000), 'dd/MM/yyyy')}</div>
          <div>Price to pay next time : {props.subscription.plan.amount/100}{props.subscription.plan.currency}</div>
          <div>Billing {props.subscription.plan.interval==="year"? "annual" : props.subscription.plan.interval==="month"? "monthly" : "error"}</div>
          {props.subscription.trial_start!==null && <div>Free trial started on : {format(new Date(props.subscription.trial_start*1000), 'dd/MM/yyyy')}</div>}
          {props.subscription.canceled_at!==null && <div>Subscription cancelled the : {format(new Date(props.subscription.canceled_at*1000), 'dd/MM/yyyy')}</div>}
          {props.subscription.cancel_at!==null && <div>End of the subscription the : {format(new Date(props.subscription.cancel_at*1000), 'dd/MM/yyyy')}</div>}
          {props.subscription.ended_at!==null && <div>Subscription ended the : {format(new Date(props.subscription.ended_at*1000), 'dd/MM/yyyy')}</div>}
          <div>Automatic renew : {props.subscription.cancel_at_period_end? "disabled" : "enabled"}</div>
        </div>
          <div className='advice-detail-subscription'>If the status isn't on "active", please contact us at gurk.contact@gmail.com in order to regularize your situation</div>
          <div className='buttons-details-container'>
          <motion.div whileHover={{scale : 1.05}} onClick={()=>props.cancel()} className='button-details-annuler'>Cancel the subscription</motion.div>
          <motion.div whileHover={{scale : 1.05}} onClick={()=>props.changePaymentDetails()} className='button-details-update'>Enter new payment Informations</motion.div>
        </div>
        </>
        )}
      </div>
  )
}

export default Subscription