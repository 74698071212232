import React from 'react'

function BasicSvg(props) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 882 882"  xmlns="http://www.w3.org/2000/svg">
        <title>Cette fonctionnalitée est réservée aux utilisateurs de Basic</title>
        <g transform="translate(-750 -797)">
            <rect x="784.5" y="831.5" width="813" height="813" stroke={props.color} strokeWidth="68.75" strokeMiterlimit="8" fill="none"/>
            <path d="M940 1408 940 1060 1287 1408Z" fill={props.color} fillRule="evenodd"/>
            <path d="M0 348 0 0 347 348Z" fill={props.color} fill-rule="evenodd" transform="matrix(-1 0 0 1 1442 1060)"/>
        </g>
    </svg>
  )
}

export default BasicSvg