import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { PiArrowLeftLight } from 'react-icons/pi'
import { CiWarning } from 'react-icons/ci'
import { format } from 'date-fns'
import axios from 'axios'
import Loader from '../utils/Loader'
import "../style/pricing/cancel.scss"

function Cancel(props) {
    const [page, setPage] = useState(0)
    
  
  const deleteSubscription = async (e) => {
    setPage(998)
    e.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/website/cancelSubscription`,
      );

      const result = response.data;

      if (result.success) {
        console.log("Unsubscription successful:", result.subscription);
        // Handle success (e.g., show a success message to the user)
        setPage(1)
      } else {
        console.error("Unsubscription failed:", result.error);
        props.changePage(999)
        // Handle failure (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error during unsubscription:", error);
      props.changePage(999)
      // Handle other errors (e.g., network issues)
    }
  };
  console.log(props.subscription)
  return (
    <div className='cancel-subscription-container'>
        {(page===0 && props.subscription) && (
            <div className='thanks-purchage-container'>
            <CiWarning size={150} color="red"/>
            <div className='thanks-text'>Are you sure you want to stop your subscription ?</div>
            {props.subscription.current_period_end && <div className='thanks-explanation'>The subscription will stop on the {format(new Date(props.subscription.current_period_end*1000), 'dd/MM/yyyy')}, Without any refund possible</div>}
            {props.subscription.end_date && <div className='thanks-explanation'>The subscription will stop on the {format(new Date(props.subscription.end_date), 'dd/MM/yyyy')}, Without any refund possible</div>}
            <div className='button-subscription-container'>
              <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={(e)=>deleteSubscription(e)} className='button-subscription-annuler'>Confirm</motion.div> 
              <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>props.returnAcceuil()} className='button-subscription-continuer'>return</motion.div>
            </div>
          </div>
        )}
        {page===1 && (
        <div className='thanks-purchage-container position-relative'>
            <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>props.returnAcceuil()} className='arrow-back-container-sad'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
            <svg style={{width : "100px", height : "100px"}} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#FFCC4D" d="M36 18c0 9.941-8.059 18-18 18c-9.94 0-18-8.059-18-18C0 8.06 8.06 0 18 0c9.941 0 18 8.06 18 18"></path><ellipse fill="#664500" cx="11.5" cy="16.5" rx="2.5" ry="3.5"></ellipse><ellipse fill="#664500" cx="24.5" cy="16.5" rx="2.5" ry="3.5"></ellipse><path fill="#664500" d="M23.485 27.879C23.474 27.835 22.34 23.5 18 23.5s-5.474 4.335-5.485 4.379a.496.496 0 0 0 .232.544a.51.51 0 0 0 .596-.06c.009-.007 1.013-.863 4.657-.863c3.59 0 4.617.83 4.656.863a.496.496 0 0 0 .59.073a.5.5 0 0 0 .239-.557z"></path><path fill="#5DADEC" d="M10 30c0 2.762-2.238 5-5 5s-5-2.238-5-5s4-10 5-10s5 7.238 5 10z"></path><path fill="#664500" d="M30 13c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 0 1 1.787-.899C23.967 7.694 25.713 11 30 11a1 1 0 1 1 0 2zM6 13a1 1 0 0 1 0-2c5.083 0 5.996-3.12 6.033-3.253c.145-.528.69-.848 1.219-.709c.53.139.851.673.718 1.205C13.921 8.437 12.704 13 6 13z"></path></g></svg>
            <div className='thanks-text'>We are sorry to see you want to leave us</div>
            <div className='thanks-explanation'>Don't hesitate to provide some feedback about gurk at gurk.contact@gmail.com</div>
        </div>
        )}
        {(page===998 || !props.subscription) && (
            <Loader width={50} color={"var(--background-color)"}/>
        )}

    </div>
  )
}

export default Cancel