import React from 'react'
import "../style/privacy.scss"

function Privacy(props) {
  return (
    <div className='privacy-page-big-container'>

    {props.title==="privacy" && (
        
    <div className='privacy-page-container'>
        <div className='header-title-privacy'>Privacy Policy</div>
        <div className='privacy-space-40px'></div>
        <div className='element-privacy'>Effective Date : February 29, 2024</div>
        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>1. Use of Gurk</div>
        <div className='description-element-privacy'>This private Policy ("Policy") outlines how gurk ("gurk," "we," "our", or "us") collects, uses, discloses and protects your personal information when you access and use the website located at {process.env.REACT_APP_PUBLIC_URL} (the "Website"), and use the google chrome extension called "gurk" (in the following, we will say "Gurk" when we want to say "this website and the chrome extension called "Gurk"). By accessing or using Gurk, you consent to the practices described in this Policy.</div>


        <div className='privacy-space-40px'></div>


        <div className='title-element-privacy'>2. Information We Collect</div>
        <div className='title-2-element-privacy'>2.1 Personal Information</div>
        <div className='description-element-privacy'>We collect and we store informations only when you create an account, and we will store : name, email, Encrypted password. We will not collect other informations, such as the number of times you visit Gurk, or your gurk settings.</div>
        <div className='title-2-element-privacy'>2.2 Automatically Collected Information</div>
        <div className='description-element-privacy'>The "weather" widget uses your IP adress to determine your location and display relative content. However, we don't store this informations into our Database</div>
        <div className='title-2-element-privacy'>2.3 Google Informations</div>
        <div className='description-element-privacy'>If you choose to link your google account, we will use the Google API to collect data for the calendar widget. We do not store this data, and Gurk use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements</div>
    
        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>3. How We Use of Information</div>
        <div className='title-2-element-privacy'>3.1 To Provide Services</div>
        <div className='description-element-privacy'>We may use your personal information to provide you with the services and features offered on Gurk and on the extension, respond to your inquiries, and fulfill your requests.</div>
        <div className='title-2-element-privacy'>3.2 To Improve Our Services</div>
        <div className='description-element-privacy'>We will not use your information to analyze user trends, conduct research, and improve the quality of Gurk and our services (this part may change in the future, but it is absolutely not our priority).</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>4. Disclosure of Your Information</div>
        <div className='title-2-element-privacy'>4.1 Third-Party Service Providers</div>
        <div className='description-element-privacy'>We may share your personal information with third-party service providers who assist us in providing and improving our services. These service providers are obligated to protect your information.</div>
        <div className='title-2-element-privacy'>4.2 Legal Requirements</div>
        <div className='description-element-privacy'>We may disclose your information when required by law, court order, or government regulation.</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>5. Your choices</div>
        <div className='title-2-element-privacy'>5.1 Opt-Out</div>
        <div className='description-element-privacy'>You can delete all your informations simply by deleting your account. For that, send a mail to gurk.contact@gmail.com, requesting the complete deletion of your account.</div>
        <div className='title-2-element-privacy'>5.2 Cookies</div>
        <div className='description-element-privacy'>Most web browsers allow you to control cookies through their settings. You can set your browser to refuse all cookies or to indicate when a cookie is being sent. However, keep in mind that the one of the only cookie we use is to secure the authentification, so it is necessary to the functionment of Gurk</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>6. Data Security</div>
        <div className='title-2-element-privacy'>6.1 Security Measures</div>
        <div className='description-element-privacy'>We implement reasonable security measures to protect your personal information from unauthorized access and disclosure.</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>7. Children's Privacy</div>
        <div className='description-element-privacy'>Gurk is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>8. Changes to This Policy</div>
        <div className='title-2-element-privacy'>8.1 Updates</div>
        <div className='description-element-privacy'>We may update this Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated Policy will be posted on this Website with the effective date.</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>9. Contact Us</div>
        <div className='title-2-element-privacy'>9.1 Questions</div>
        <div className='description-element-privacy'>If you have any questions about this Policy, please contact us at gurk.contact@gmail.com.</div>

        <div className='description-element-privacy'>By using Gurk, you acknowledge that you have read, understood, and agree to this Privacy Policy.</div>
    </div> 
    )}

    {props.title==="legal" && (
    <div className='privacy-page-container'>
        <div className='header-title-privacy'>Legal Informations</div>
        <div className='privacy-space-40px'></div>
        <div className='element-privacy'>Effective Date : February 29, 2024</div>
        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>Website Editor</div>

        <div className='description-element-privacy'>The website {process.env.REACT_APP_PUBLIC_URL} is edited by :</div>
        <div className='description-2-element-privacy'>Social denomination : ????</div>
        <div className='description-2-element-privacy'>Lagal Status : ????</div>
        <div className='description-2-element-privacy'>Share Capital : ????</div>
        <div className='description-2-element-privacy'>Head office : EPFL BC09, Lausanne Switzerland</div>
        <div className='description-2-element-privacy'>RCS : Lausanne</div>
        <div className='description-2-element-privacy'>Siret : ????</div>
        <div className='description-2-element-privacy'>VAT number : ????</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>Publication Director</div>

        <div className='description-element-privacy'>The extension and website Gurk is published by :</div>

        <div className='description-2-element-privacy'>Physical person : Emmanuel OMONT</div>
        <div className='description-2-element-privacy'>Status : Director</div>
        <div className='description-2-element-privacy'>Head office : EPFL BC09, Lausanne Switzerland</div>
        <div className='description-2-element-privacy'>Email : gurk.contact@gmail.com</div>

        <div className='privacy-space-40px'></div>

        <div className='title-element-privacy'>Website Hosting</div>

        <div className='description-element-privacy'>The website is hosted by :</div>
        <div className='description-2-element-privacy'>Social denomination : SAS PulseHeberg</div>
        <div className='description-2-element-privacy'>Head office : 9, Boulevard de Strasbourg 83000 Toulon</div>
    </div>
    )}

    {props.title==="terms" && (
        <div className='privacy-page-container'>
            <div className='header-title-privacy'>Terms and Conditions</div>
            <div className='privacy-space-40px'></div>
            <div className='element-privacy'>Effective Date : February 29, 2024</div>
            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>1. Use of Gurk</div>
            <div className='title-2-element-privacy'>1.1 Access</div>
            <div className='description-element-privacy'>You must be at least 13 years old to access and use Gurk. By using Gurk, you represent and warrant that you are of legal age.</div>
            <div className='title-2-element-privacy'>1.2 Account</div>
            <div className='description-element-privacy'>Some features of Gurk may require you to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</div>
            <div className='title-2-element-privacy'>1.3 Prohibited Activities</div>
            <div className='description-element-privacy'>You agree not to use Gurk for any unlawful or unauthorized purpose. You may not: <br/><br/> a) Violate any applicable laws or regulations. <br/> b) Infringe upon the rights of others, including intellectual property rights. <br/> c) Distribute spam or engage in any form of malicious activity.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>2. Privacy</div>
            <div className='title-2-element-privacy'>2.1 Privacy Policy</div>
            <div className='description-element-privacy'>Your use of Gurk is also governed by our Privacy Policy, which can be found at {process.env.REACT_APP_PUBLIC_URL}/privacy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>3. Intellectual Property</div>
            <div className='title-2-element-privacy'>3.1 Ownership</div>
            <div className='description-element-privacy'>All content on Gurk, including but not limited to text, graphics, logos, images, and software, is the property of gurk and is protected by copyright and other intellectual property laws.</div>
            <div className='title-2-element-privacy'>3.2 Use of Content</div>
            <div className='description-element-privacy'>You may not use, reproduce, or distribute any content from Gurk without our prior written consent.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>4. Disclaimer of Warranties</div>
            <div className='title-2-element-privacy'>4.1 No Warranty</div>
            <div className='description-element-privacy'>Gurk is provided on an "as-is" and "as-available" basis. We make no representations or warranties regarding the accuracy, completeness, or reliability of the content on Gurk.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>5. Limitation of Liability</div>
            <div className='title-2-element-privacy'>5.1 Limitation</div>
            <div className='description-element-privacy'>gurk and its affiliates, officers, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.</div>
        
            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>6. Termination</div>
            <div className='title-2-element-privacy'>6.1 Termination</div>
            <div className='description-element-privacy'>We reserve the right to terminate or suspend your access to Gurk at our sole discretion, without notice and for any reason, including but not limited to your violation of these Terms.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>7. Governing Law</div>
            <div className='title-2-element-privacy'>7.1 Governing Law</div>
            <div className='description-element-privacy'>These Terms are governed by the laws of Switzerland.</div>

            <div className='privacy-space-40px'></div>

            <div className='title-element-privacy'>8. Contact Information</div>
            <div className='title-2-element-privacy'>8.1 Contact Us</div>
            <div className='description-element-privacy'>If you have any questions about these Terms, please contact us at gurk.contact@gmail.com</div>

            <div className='description-element-privacy'>By using Gurk, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</div>
        </div>

    )}
    </div>
  )
}

export default Privacy