import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "../style/google.scss"
import "../style/pricing/success.scss"
import Loader from '../utils/Loader';
import {motion } from 'framer-motion'
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { PiArrowLeftLight } from 'react-icons/pi';
import { CiCircleCheck } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

function Google() {
  const [code, setCode] = useState(null);

  const [state, setState] = useState(0);

  const sendCode = async ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    try{
      if (code) {
        setCode(code);

        // Remove the code from the URL
        urlParams.delete('code');
        window.history.replaceState(null, null, window.location.pathname);

        // Send the code to your backend server
        const response =  await axios.post(`${process.env.REACT_APP_API_URL}/api/website/googlecode`, { code : code }, { withCredentials: true })
        if (response.data.success) {
          setState(1);
        } else {
          setState(2);
        }
      } else {
        setState(2);
      }  
    } catch (error) {
      console.error(error)
      setState(2);
    }
    
  }

  let navigate = useNavigate()

  const returnAccueil = () => {
    navigate("/dashboard?page=0");
    window.location.reload();
  }

  useEffect(() => {
    // Parse the URL query string
    sendCode()
  }, []);

  return (
    <div className='google-container'>
      {state === 0 && <Loader width={30} color={"black"}/>}
      {state === 1 && (
        <div className='google-small-container'>
          <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAccueil()} className='arrow-back-container-google'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
          <CiCircleCheck size={100} color="green" strokeWidth='1px'/>
          <div className='thanks-text'>Your Google account has been linked</div>
        </div>
      )}
      {state === 2 && (
        <div className='google-small-container'>
          <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>returnAccueil()} className='arrow-back-container-google'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
          <IoIosCloseCircleOutline size={100} color='red' strokeWidth="1px" />
          <div className='thanks-text'>Oups ! there was a problem. Please try again later</div>
        </div>
      )}
    </div>
  )
}

export default Google