import React from 'react'
import { motion } from 'framer-motion'
import { PiArrowLeftLight } from "react-icons/pi";
import { CiCircleCheck  } from "react-icons/ci";
import "../style/pricing/success.scss"


function Success(props) {
  return (
    <div className='thanks-purchage-container'>
    <motion.div whileHover={{scale : 1.1, cursor : "pointer"}} onClick={()=>props.returnAcceuil()} className='arrow-back-container-thanks-page'><PiArrowLeftLight size={30} strokeWidth=""/></motion.div>
    <CiCircleCheck size={100} color="green" strokeWidth='1px'/>
    <div className='thanks-text'>Thanks for your trust !</div>
    {!props.message &&
    <div className='thanks-explanation'>If you didn't already make a free trial, it will begin now. You have until the end of this free trial to cancel the subscription if you don't want it, and you will not be charged. Otherwise, the payments will begin at the end of the free trial</div>
    }
  </div>
  )
}

export default Success